const lokaliseTags = {
  SSO_ACCEPT_MARKETING_CONSENTS: 'sso_accept_marketing_consents',
  SSO_ACCOUNT_LOCKED_CONTINUE_WITHOUT_CARD:
    'sso_account_locked_continue_without_card',
  SSO_ACCOUNT_LOCKED_INFO_TEXT: 'sso_account_locked_locked_info_text',
  SSO_ACCOUNT_LOCKED_LABEL: 'sso_account_locked_locked_label',
  SSO_ACCOUNT_LOCKED_MESSAGE_TEXT: 'sso_account_locked_locked_message_text',
  SSO_ACCOUNT_LOCKED_RESET_PASSWORD: 'sso_account_locked_reset_password',
  SSO_ADAC_CARD_NOT_SUPPORTED_TEXT: 'sso_adac_card_not_supported_text',
  SSO_ADAC_UNVERIFIED_MEMBERSHIP_TEXT: 'sso_adac_unverified_membership_text',
  SSO_ADAC_UNVERIFIED_MEMBERSHIP_TITLE: 'sso_adac_unverified_membership_title',
  SSO_ADD_NEW_CARD_LINK: 'sso_add_new_card_link',
  SSO_ADD_REPLACEMENT_CARD_LINK: 'sso_add_replacement_card_link',
  SSO_ATTACH_CARD_BUTTON: 'sso_attach_card_button',
  SSO_ATTACH_CARD_ID_VALIDATION_DISCLAIMER:
    'sso_attach_card_id_validation_disclaimer',
  SSO_ATTACH_CARD_FUELCARD_DIALOG_NO: 'sso_attach_card_fuelcard_dialog_no',
  SSO_ATTACH_CARD_FUELCARD_DIALOG_TEXT: 'sso_attach_card_fuelcard_dialog_text',
  SSO_ATTACH_CARD_FUELCARD_DIALOG_TITLE:
    'sso_attach_card_fuelcard_dialog_title',
  SSO_ATTACH_CARD_FUELCARD_DIALOG_YES: 'sso_attach_card_fuelcard_dialog_yes',
  SSO_ATTACH_CARD_REPLACEMENT_NOT_ALLOWED_TEXT:
    'sso_attach_card_replacement_not_allowed_text',
  SSO_ATTACH_CARD_REPLACEMENT_NOT_ALLOWED_TITLE:
    'sso_attach_card_replacement_not_allowed_title',
  SSO_ATTACH_CONTINUE_BUTTON: 'sso_attach_continue_button',
  SSO_ATTACH_CREATE_CARD_BUTTON: 'sso_attach_create_card_button',
  SSO_ATTACH_DIGITAL_MODAL_SCREEN_CHANGE_NOT_REVERTED:
    'sso_attach_digital_modal_screen_change_not_reverted',
  SSO_ATTACH_DIGITAL_MODAL_SCREEN_TEXT: 'sso_attach_digital_modal_screen_text',
  SSO_ATTACH_DIGITAL_MODAL_SCREEN_TITLE:
    'sso_attach_digital_modal_screen_title',
  SSO_ATTACH_DIGITAL_MODAL_TC: 'sso_attach_digital_modal_t&c',
  SSO_ATTACH_FIND_CARD_FRONT_OR_BACK_DIALOGUE:
    'sso_attach_find_card_front_or_back_dialogue',
  SSO_ATTACH_LOYALTY_CARD_HELP_BUTTON: 'sso_attach_loyalty_card_help_button',
  SSO_ATTACH_LOYALTY_CARD_NEXT_BUTTON: 'sso_attach_loyalty_card_next_button',
  SSO_ATTACH_LOYALTY_CARD_TEXT: 'sso_attach_loyalty_card_text',
  SSO_ATTACH_LOYALTY_CARD_TITLE: 'sso_attach_loyalty_card_title',
  SSO_ATTACH_MULTICARD_B2C_B2B_TEXT: 'sso_attach_multicard_b2c_b2b_text',
  SSO_ATTACH_MULTICARD_B2C_B2B_TITLE: 'sso_attach_multicard_b2c_b2b_title',
  SSO_ATTACH_MULTICARD_B2C_TEXT: 'sso_attach_multicard_b2c_text',
  SSO_ATTACH_MULTICARD_CREATE_B2B_BUTTON:
    'sso_attach_multicard_create_b2b_button',
  SSO_ATTACH_MULTICARD_CREATE_BUTTON: 'sso_attach_multicard_create_button',
  SSO_ATTACH_MULTICARD_PHYSICAL_BUTTON: 'sso_attach_multicard_physical_button',
  SSO_ATTACH_MULTICARD_PHYSICAL_CONTINUE_BUTTON:
    'sso_attach_multicard_physical_continue_button',
  SSO_ATTACH_MULTICARD_PHYSICAL_TEXT: 'sso_attach_multicard_physical_text',
  SSO_ATTACH_SECURITY_STEP1: 'sso_attach_security_step1',
  SSO_ATTACH_SECURITY_STEP2: 'sso_attach_security_step2',
  SSO_ATTACH_SECURITY_STEP3: 'sso_attach_security_step3',
  SSO_BACK: 'sso_back',
  SSO_CARD_LOCKED_LABEL: 'sso_card_locked_label',
  SSO_CARD_LOCKED_MESSAGE_TEXT: 'sso_card_locked_message_text',
  SSO_CARD_NOT_SUPPORTED_TEXT: 'sso_card_not_supported_text',
  SSO_CARD_NOT_SUPPORTED_TITLE: 'sso_card_not_supported_title',
  SSO_CONTINUE_WITHOUT_EMAIL: 'sso_continue_without_email',
  SSO_CONTINUE_WITHOUT_EMAIL_BUTTON: 'sso_continue_without_email_button',
  SSO_DELETE_VERIFY_BEFORE_DELETE: 'sso_delete_verify_before_delete',
  SSO_PHONE_CONFIRMATION_SCREEN_TITLE: 'sso_phone_confirmation_screen_title',
  SSO_EMAIL_CONFIRMATION_SCREEN_BUTTON: 'sso_email_confirmation_screen_button',
  SSO_EMAIL_CONFIRMATION_SCREEN_TITLE: 'sso_email_confirmation_screen_title',
  SSO_EMAIL_CONFIRMATION_SCREEN_TEXT: 'sso_email_confirmation_screen_text',
  SSO_EMAIL_CONFIRMATION_SCREEN_SUBTITLE:
    'sso_email_confirmation_screen_subtitle',
  SSO_ENTER_PASSWORD_TEXT: 'sso_enter_password_text',
  SSO_ENTER_PASSWORD_TITLE: 'sso_enter_password_title',
  SSO_ERROR_CARD_NOT_SUPPORTED: 'sso_error_card_not_supported',
  SSO_ERROR_CARD_NOT_SUPPORTED_TEXT: 'sso_error_card_not_supported_text',
  SSO_ERROR_GENERIC_JANRAIN: 'sso_error_generic_janrain',
  SSO_ERROR_GENERIC_JANRAIN_MIGRATION_FAILED_TEXT:
    'sso_error_generic_janrain_migration_failed_text',
  SSO_ERROR_GENERIC_SOL_MIGRATION_FAILED_TEXT:
    'sso_error_generic_sol_migration_failed_text',
  SSO_FORGOT_PASSWORD_SECURITY_UPDATE_TITLE:
    'sso_forgot_password_security_update_title',
  SSO_FORMS_CARD_ID: 'sso_forms_card_id',
  SSO_FORMS_CURRENT_PASSWORD: 'sso_forms_current_password',
  SSO_FORMS_EMPTY_OTP: 'sso_forms_empty_otp',
  SSO_FORMS_ENTER_PASSWORD: 'sso_forms_enter_password',
  SSO_FORMS_ERRORS_BLOCKED_CARD: 'sso_forms_errors_blocked_card',
  SSO_FORMS_ERRORS_CARD_ALREADY_MIGRATED:
    'sso_forms_errors_card_already_migrated',
  SSO_FORMS_ERRORS_CARD_ALREADY_MIGRATED_SOL:
    'sso_forms_errors_card_already_migrated_sol',
  SSO_FORMS_ERRORS_CARD_EMAIL_INVALID: 'sso_forms_errors_card_email_invalid',
  SSO_FORMS_ERRORS_CARD_NOT_VALID: 'sso_forms_errors_card_not_valid',
  SSO_FORMS_ERRORS_CARD_REGISTERED: 'sso_forms_errors_card_registered',
  SSO_FORMS_ERRORS_CSC_EMAIL_ERROR: 'sso_forms_errors_csc_email_error',
  SSO_FORMS_ERRORS_DEVICE_CANNOT_LOGIN: 'sso_forms_errors_device_cannot_login',
  SSO_FORMS_ERRORS_DISABLED_CARD: 'sso_forms_errors_disabled_card',
  SSO_FORMS_ERRORS_MOBILE_PHONE_IS_ALREADY_IN_USE:
    'sso_forms_errors_mobile_phone_is_already_in_use',
  SSO_FORMS_ERRORS_EMAIL_ADDRESS_IS_ALREADY_IN_USE:
    'sso_forms_errors_email_address_is_already_in_use',
  SSO_FORMS_ERRORS_EMAIL_ADDRESS_IS_REQUIRED:
    'sso_forms_errors_email_address_is_required',
  SSO_FORMS_ERRORS_EMAIL_REGISTERED: 'sso_forms_errors_email_registered',
  SSO_FORMS_ERRORS_EMAIL_UNKNOWN: 'sso_forms_errors_email_unknown',
  SSO_FORMS_ERRORS_INCORRECT_USERNAME_OR_PASSWORD_PLEASE_TRY_AGAIN:
    'sso_forms_errors_incorrect_username_or_password_please_try_again',
  SSO_FORMS_ERRORS_INVALID_CARD: 'sso_forms_errors_invalid_card',
  SSO_FORMS_ERRORS_INVALID_CARDID: 'sso_forms_errors_invalid_cardid',
  SSO_FORMS_ERRORS_INVALID_CARDID_ERROR:
    'sso_forms_errors_invalid_cardid_error',
  SSO_FORMS_ERRORS_INVALID_CURRENT_PASSWORD:
    'sso_forms_errors_invalid_current_password',
  SSO_FORMS_ERRORS_INVALID_PHONE_NUMBER:
    'sso_forms_errors_invalid_phone_number',
  SSO_FORMS_ERRORS_NO_ACCOUNT_WITH_THAT_PHONE_NUMBER_EXISTS:
    'sso_forms_errors_no_account_with_that_phone_number_exists',
  SSO_FORMS_ERRORS_NO_ACCOUNTS_WITH_THAT_EMAIL_ADDRESS_EXISTS:
    'sso_forms_errors_no_account_with_that_email_address_exists',
  SSO_FORMS_ERRORS_PASSWORD_IS_REQUIRED:
    'sso_forms_errors_password_is_required',
  SSO_FORMS_ERRORS_PASSWORD_NOT_VALID: 'sso_forms_errors_password_not_valid',
  SSO_FORMS_ERRORS_PASSWORD_POLICY_CONFLICT:
    'sso_forms_errors_password_policy_conflict',
  SSO_FORMS_ERRORS_PHONE_EXISTS_LOD: 'sso_forms_errors_phone_exists_lod',
  SSO_FORMS_ERRORS_CARDID_CANNOT_ADD_EXTRA_CARD:
    'sso_forms_errors_cardid_cannot_add_extra_card',
  SSO_FORMS_ERRORS_PHONE_NUMBER: 'sso_forms_errors_phone_number',
  SSO_FORMS_ERRORS_USERNAME_PASSWORD_INVALID:
    'sso_forms_errors_username_password_invalid',
  SSO_FORMS_ERRORS_WRONG_OTP: 'sso_forms_errors_wrong_otp',
  SSO_FORMS_LINK_USE_DOB: 'sso_forms_link_use_dob',
  SSO_FORMS_LINK_USE_PHONE_NUMBER: 'sso_forms_link_use_phone_number',
  SSO_FORMS_NO_OPTIONS_AVAILABLE: 'sso_forms_no_options_available',
  SSO_GENDER_FEMALE: 'sso_gender_female',
  SSO_GENDER_MALE: 'sso_gender_male',
  SSO_GENDER_UNDISCLOSED: 'sso_gender_undisclosed',
  SSO_GENERAL_ACCEPT_LEGAL_BUTTON: 'sso_general_accept_legal_button',
  SSO_GENERAL_ACCEPT_LEGAL_GOPLUS_SETUP_HEADER:
    'sso_general_accept_legal_goplus_setup_header',
  SSO_GENERAL_ACCEPT_LEGAL_MIGRATE_HEADER:
    'sso_general_accept_legal_migrate_header',
  SSO_GENERAL_ACCEPT_LEGAL_REGISTER_HEADER:
    'sso_general_accept_legal_register_header',
  SSO_GENERAL_ACTIVATE_ERROR_TEXT: 'sso_general_activate_error_text',
  SSO_GENERAL_BACK: 'sso_general_back',
  SSO_GENERAL_CANCEL: 'sso_general_cancel',
  SSO_GENERAL_CANCEL_BUTTON: 'sso_general_cancel_button',
  SSO_GENERAL_CLOSE: 'sso_general_close',
  SSO_GENERAL_CONTINUE: 'sso_general_continue',
  SSO_GENERAL_DATE_FORMAT: 'sso_general_date_format',
  SSO_GENERAL_DONE_LABEL: 'sso_general_done_label',
  SSO_GENERAL_DYNAMO_UNAVAILABLE_HEADER:
    'sso_general_dynamo_unavailable_header',
  SSO_GENERAL_DYNAMO_UNAVAILABLE_TEXT: 'sso_general_dynamo_unavailable_text',
  SSO_GENERAL_ERROR: 'sso_general_error',
  SSO_GENERAL_FACEBOOK_BUTTON: 'sso_general_facebook_button',
  SSO_GENERAL_GET_STARTED_LABEL: 'sso_general_get_started_label',
  SSO_GENERAL_GO_BACK: 'sso_general_go_back',
  SSO_GENERAL_GOOGLE_CAPTCHA_TEXT: 'sso_general_google_captcha_text',
  SSO_GENERAL_GOT_IT_LABEL: 'sso_general_got_it_label',
  SSO_GENERAL_HELPFUL_UPDATES_GO_PLUS_TEXT:
    'sso_general_helpful_updates_goplus_text',
  SSO_GENERAL_HELPFUL_UPDATES_GO_PLUS_TITLE:
    'sso_general_helpful_updates_goplus_title',
  SSO_GENERAL_HELPFUL_UPDATES_NO_BUTTON:
    'sso_general_helpful_updates_no_button',
  SSO_GENERAL_HELPFUL_UPDATES_PROMPT_BUTTON:
    'sso_general_helpful_updates_prompt_button',
  SSO_GENERAL_HELPFUL_UPDATES_PROMPT_TEXT:
    'sso_general_helpful_updates_prompt_text',
  SSO_GENERAL_HELPFUL_UPDATES_PROMPT_TITLE:
    'sso_general_helpful_updates_prompt_title',
  SSO_GENERAL_HELPFUL_UPDATES_TEXT: 'sso_general_helpful_updates_text',
  SSO_GENERAL_HELPFUL_UPDATES_TITLE: 'sso_general_helpful_updates_title',
  SSO_GENERAL_HELPFUL_UPDATES_YES_BUTTON:
    'sso_general_helpful_updates_yes_button',
  SSO_GENERAL_LEARN_MORE_LINK: 'sso_general_learn_more_link',
  SSO_GENERAL_LOGOUT_LABEL: 'sso_general_logout_label',
  SSO_GENERAL_MISSING_MARKET_FORMAT: 'sso_general_missing_market_format',
  SSO_GENERAL_NEXT: 'sso_general_next',
  SSO_GENERAL_NEXT_LABEL: 'sso_general_next_label',
  SSO_GENERAL_NO_BACKEND_CONNECTION_HEADER:
    'sso_general_no_backend_connection_header',
  SSO_GENERAL_NO_BACKEND_CONNECTION_TEXT:
    'sso_general_no_backend_connection_text',
  SSO_GENERAL_NO_CONTINUE: 'sso_general_no_continue',
  SSO_GENERAL_NO_INTERNET_CONNECTION_HEADER:
    'sso_general_no_internet_connection_header',
  SSO_GENERAL_NO_INTERNET_CONNECTION_TEXT:
    'sso_general_no_internet_connection_text',
  SSO_GENERAL_OK: 'sso_general_ok',
  SSO_GENERAL_OPEN_EMAIL_BUTTON: 'sso_general_open_email_button',
  SSO_GENERAL_SAVE_LABEL: 'sso_general_save_label',
  SSO_GENERAL_SIGN_OUT: 'sso_general_sign_out',
  SSO_GENERAL_TC_PRIVACY_POLICY: 'sso_general_tc_privacy_policy',
  SSO_GENERAL_TRY_AGAIN: 'sso_general_try_again',
  SSO_GENERAL_UPDATED_EMAIL_TEXT: 'sso_general_updated_email_text',
  SSO_GENERAL_UPDATED_EMAIL_TITLE: 'sso_general_updated_email_title',
  SSO_GENERAL_UPDATED_PHONE_TEXT: 'sso_general_updated_phone_text',
  SSO_GENERAL_UPDATED_PHONE_TITLE: 'sso_general_updated_phone_title',
  SSO_GENERAL_WRONG_MARKET: 'sso_general_wrong_market',
  SSO_GENERAL_WRONG_MARKET_FORMAT: 'sso_general_wrong_market_format',
  SSO_GENERAL_WRONG_MARKET_TEXT: 'sso_general_wrong_market_text',
  SSO_GENERIC_COCO: 'sso_error_generic_coco',
  SSO_GENERIC_LOD: 'sso_error_generic_lod',
  SSO_GENERIC_SOL: 'sso_error_generic_sol',
  SSO_LOGIN_CANCEL_BUTTON: 'sso_login_cancel_button',
  SSO_LOGIN_CARD_ALREADY_EXISTS_DIALOG: 'sso_login_card_already_exists_dialog',
  SSO_LOGIN_CARD_ID_BODY: 'sso_login_card_id_body',
  SSO_LOGIN_CARD_ID_HELP_BUTTON: 'sso_login_card_id_help_button',
  SSO_LOGIN_CARD_ID_NEXT_BUTTON: 'sso_login_card_id_next_button',
  SSO_LOGIN_CARD_ID_TITLE: 'sso_login_card_id_title',
  SSO_LOGIN_CARD_ID_VALIDATION_TEXT: 'sso_login_card_id_validation_text',
  SSO_LOGIN_EMAIL_INSTEAD_BUTTON: 'sso_login_email_instead_button',
  SSO_LOGIN_FORGOT_PASSWORD: 'sso_login_forgot_password',
  SSO_LOGIN_LABEL: 'sso_login_login_label',
  SSO_LOGIN_LAST_ATTEMPT_TEXT: 'sso_login_last_attempt_text',
  SSO_LOGIN_LAST_ATTEMPT_TITLE: 'sso_login_last_attempt_title',
  SSO_LOGIN_MPP_FORGOT_PIN: 'sso_login_mpp_forgot_pin',
  SSO_LOGIN_PHONE_INSTEAD_BUTTON: 'sso_login_phone_instead_button',
  SSO_LOGIN_RESET_PASSWORD_LABEL: 'sso_login_reset_password_label',
  SSO_LOGIN_SIGN_IN_BUTTON: 'sso_login_sign_in_button',
  SSO_AIRMILES_LOGIN_SIGN_IN_BUTTON: 'sso_airmiles_sign_in_button',
  SSO_LOGIN_SIGN_IN_LINK: 'sso_login_sign_in_link',
  SSO_LOGIN_SIGN_IN_OPTION_CARD_NUMBER: 'sso_login_sign_in_option_card_number',
  SSO_LOGIN_SIGN_IN_OPTION_TEXT: 'sso_login_sign_in_option_text',
  SSO_LOGIN_SIGN_IN_TEXT: 'sso_login_sign_in_text',
  SSO_LOGIN_SIGN_IN_TITLE: 'sso_login_sign_in_title',
  SSO_LOGIN_SOCIAL_EMAIL_MISMATCH: 'sso_login_social_email_mismatch',
  SSO_LOGIN_SOCIAL_EMAIL_MISMATCH_TEXT: 'sso_login_social_email_mismatch_text',
  SSO_LOGIN_TRY_AGAIN: 'sso_login_try_again',
  SSO_LOGIN_VALIDATION_DATA_NEEDED_ERROR_CREDENTIALS:
    'sso_login_validation_data_needed_error_credentials',
  SSO_LOYALTY_ACTIVATE_ERROR_TITLE: 'sso_loyalty_activate_error_title',
  SSO_LOYALTY_ADDRESS_DETAILS_TITLE: 'sso_loyalty_address_details_title',
  SSO_LOYALTY_CARD_BLOCKED: 'sso_loyalty_card_blocked',
  SSO_LOYALTY_CARD_BLOCKED_TEXT: 'sso_loyalty_card_blocked_text',
  SSO_LOYALTY_CARD_IS_UNDER_REVIEW: 'sso_loyalty_card_is_under_review',
  SSO_LOYALTY_CHOOSE_LOYALTY_CARD: 'sso_loyalty_choose_loyalty_card',
  SSO_LOYALTY_COMPLETE_ADDRESS: 'sso_loyalty_complete_address',
  SSO_LOYALTY_CONNECT: 'sso_loyalty_connect',
  SSO_LOYALTY_CUSTOMER_TYPE_TEXT: 'sso_loyalty_customer_type_text',
  SSO_LOYALTY_INFORMATION_TEXT: 'sso_loyalty_information_text',
  SSO_LOYALTY_LOADING_TEXT: 'sso_loyalty_loading_text',
  SSO_LOYALTY_LOADING_TITLE: 'sso_loyalty_loading_title',
  SSO_LOYALTY_MIGRATION_FAILED: 'sso_loyalty_migration_failed',
  SSO_LOYALTY_PHYSICAL_CARD: 'sso_loyalty_physical_card',
  SSO_LOYALTY_REWARD_HEADER: 'sso_loyalty_loyalty_reward_header',
  SSO_LOYALTY_SETUP_LOYALTY_COMPLETED_BUTTON:
    'sso_loyalty_setup_loyalty_completed_button',
  SSO_LOYALTY_SETUP_LOYALTY_COMPLETED_TEXT:
    'sso_loyalty_setup_loyalty_completed_text',
  SSO_LOYALTY_SETUP_LOYALTY_COMPLETED_TITLE:
    'sso_loyalty_setup_loyalty_completed_title',
  SSO_LOYALTY_SETUP_LOYALTY_TITLE: 'sso_loyalty_setup_loyalty_title',
  SSO_LOYALTY_SHELL_DRIVERS_CLUB: 'sso_loyalty_shell_drivers_club',
  SSO_LOYALTY_SIGN_IN: 'sso_loyalty_sol_sign_in',
  SSO_LOYALTY_SOL_CARD_BLOCKED: 'sso_loyalty_sol_card_blocked',
  SSO_LOYALTY_SOL_CARD_BLOCKED_TEXT: 'sso_loyalty_sol_card_blocked_text',
  SSO_LOYALTY_SOL_MIGRATION: 'sso_loyalty_sol_migration',
  SSO_LOYALTY_SOL_PASSWORD_RESET_TEXT: 'sso_loyalty_sol_password_reset_text',
  SSO_LOYALTY_SOL_PASSWORD_RESET_TITLE: 'sso_loyalty_sol_password_reset_title',
  SSO_MANAGE_CARDS: 'sso_manage_cards',
  SSO_MERGE_DIALOG_BUTTON: 'sso_merge_dialog_button',
  SSO_MERGE_DIALOG_TEXT: 'sso_merge_dialog_text',
  SSO_MERGE_DIALOG_TITLE: 'sso_merge_dialog_title',
  SSO_MFA_OTP_BODY: 'sso_mfa_otp_body',
  SSO_MFA_OTP_ERROR_EXPIRED: 'sso_mfa_otp_error_expired',
  SSO_MFA_OTP_ERROR_INVALID: 'sso_mfa_otp_error_invalid',
  SSO_MFA_OTP_OPENEMAIL_LINK: 'sso_mfa_otp_openemail_link',
  SSO_MFA_OTP_PRIMARY_DEVICE_BODY: 'sso_mfa_otp_primary_device_body',
  SSO_MFA_OTP_TITLE: 'sso_mfa_otp_title',
  SSO_MFA_OTP_TROUBLE_LINK: 'sso_mfa_otp_trouble_link',
  SSO_MFA_OTP_VERIFY_BUTTON: 'sso_mfa_otp_verify_button',
  SSO_MFA_OTP_INFO_DETAILS_TITLE: 'sso_mfa_otp_info_details_title',
  SSO_MFA_OTP_INFO_DETAILS_RESEND_BUTTON:
    'sso_mfa_otp_info_details_resend_button',
  SSO_MFA_OTP_INFO_DETAILS_RESEND_COUNTDOWN_BODY:
    'sso_mfa_otp_info_details_resend_countdown_body',
  SSO_MFA_OTP_INFO_DETAILS_DIVIDER1: 'sso_mfa_otp_info_details_divider1',
  SSO_MFA_OTP_INFO_DETAILS_DIVIDER1_BODY:
    'sso_mfa_otp_info_details_divider1_body',
  SSO_MFA_OTP_INFO_DETAILS_ITEM1_BODY: 'sso_mfa_otp_info_details_item1_body',
  SSO_MFA_OTP_INFO_DETAILS_ITEM2_BODY: 'sso_mfa_otp_info_details_item2_body',
  SSO_MFA_OTP_INFO_DETAILS_ITEM3_BODY: 'sso_mfa_otp_info_details_item3_body',
  SSO_MFA_OTP_INFO_DETAILS_ITEM4_BODY: 'sso_mfa_otp_info_details_item4_body',
  SSO_MFA_OTP_INFO_DETAILS_DIVIDER2: 'sso_mfa_otp_info_details_divider2',
  SSO_MFA_OTP_INFO_DETAILS_LINK: 'sso_mfa_otp_info_details_link',
  SSO_MFA_OTP_INFO_DETAILS_RESEND_SNACKBAR:
    'sso_mfa_otp_info_details_resend_snackbar',
  TWOFA_OTP_INFO_DETAILS_TITLE: '2fa_otp_info_details_title',
  TWOFA_OTP_INFO_DETAILS_ITEM1_BODY: '2fa_otp_info_details_item1_body',
  TWOFA_OTP_INFO_DETAILS_ITEM2_BODY: '2fa_otp_info_details_item2_body',
  TWOFA_OTP_INFO_DETAILS_ITEM3_BODY: '2fa_otp_info_details_item3_body',
  TWOFA_OTP_INFO_DETAILS_RESEND_SMS_BUTTON:
    '2fa_otp_info_details_resend_sms_button',
  TWOFA_OTP_INFO_DETAILS_CALL_TO_BUTTON: '2fa_otp_info_details_call_to_button',
  TWOFA_OTP_INFO_DETAILS_RESEND_VOICE_SNACKBAR:
    '2fa_otp_info_details_resend_voice_snackbar',
  TWOFA_OTP_INFO_DETAILS_RESEND_SMS_SNACKBAR:
    '2fa_otp_info_details_resend_sms_snackbar',
  TWOFA_OTP_INFO_DETAILS_DIVIDER1: '2fa_otp_info_details_divider1',
  TWOFA_OTP_INFO_DETAILS_DIVIDER1_BODY: '2fa_otp_info_details_divider1_body',
  TWOFA_OTP_INFO_DETAILS_CHANGE_NUMBER_LINK:
    '2fa_otp_info_details_change_number_link',
  TWOFA_EXPIRED_PHONE_NUMBER_MESSAGE: '2fa_expired_phone_number_message',
  TWOFA_OTP_SESSION_EXPIRED_ERROR_TITLE: '2fa_otp_session_expired_error_title',
  TWOFA_OTP_SESSION_EXPIRED_ERROR_BODY: '2fa_otp_session_expired_error_body',
  TWOFA_OTP_SESSION_EXPIRED_ERROR_CTA: '2fa_otp_session_expired_error_cta',
  TWOFA_OTP_DISABLE_SUCCESS_SNACKBAR: '2fa_otp_disable_success_snackbar',
  SSO_2FA_OTP_INFO_DETAILS_VOICE_RESEND_COUNTDOWN_BODY:
    'sso_2fa_otp_info_details_voice_resend_countdown_body',
  SSO_MIGRATION_ATTACH_NEW_CARD: 'sso_migration_attach_new_card',
  SSO_MIGRATION_CONFIRM_EMAIL_PROMPT_SIGN_IN:
    'sso_migration_confirm_email_prompt_sign_in',
  SSO_MIGRATION_CONTINUE: 'sso_migration_continue',
  SSO_MIGRATION_CONTINUE_BUTTON: 'sso_migration_continue_button',
  SSO_MIGRATION_ENTER_EMAIL: 'sso_migration_enter_email',
  SSO_MIGRATION_GET_STARTED: 'sso_migration_get_started',
  SSO_MIGRATION_PROMPT_CARD_EXISTS: 'sso_migration_prompt_card_exists',
  SSO_MIGRATION_PROMPT_EMAIL_EXISTS: 'sso_migration_prompt_email_exists',
  SSO_MIGRATION_PROMPT_TEXT_PHONE_EXISTS:
    'sso_migration_prompt_text_phone_exists',
  SSO_MIGRATION_READ_LESS: 'sso_migration_read_less',
  SSO_MIGRATION_READ_MORE: 'sso_migration_read_more',
  SSO_MIGRATION_SECURITY_UPDATE_STEP1: 'sso_migration_security_update_step1',
  SSO_MIGRATION_SECURITY_UPDATE_STEP2: 'sso_migration_security_update_step2',
  SSO_MIGRATION_SECURITY_UPDATE_STEP3: 'sso_migration_security_update_step3',
  SSO_MIGRATION_SECURITY_UPDATED_BODY: 'sso_migration_security_updated_body',
  SSO_MIGRATION_SECURITY_UPDATED_HEADER:
    'sso_migration_security_updated_header',
  SSO_MIGRATION_SECURITY_UPDATED_TEXT: 'sso_migration_security_updated_text',
  SSO_MIGRATION_SHELL_ACCOUNT_CREATE_PASSWORD:
    'sso_migration_shell_account_create_password',
  SSO_MIGRATION_SHELL_ACCOUNT_ID_SCREEN:
    'sso_migration_shell_account_id_screen',
  SSO_MIGRATION_SHELL_ACCOUNT_SERVICES_HEADER:
    'sso_migration_shell_account_services_header',
  SSO_MIGRATION_SHELL_ACCOUNT_SERVICES_STEP1:
    'sso_migration_shell_account_services_step1',
  SSO_MIGRATION_SHELL_ACCOUNT_SERVICES_STEP1_TEXT:
    'sso_migration_shell_account_services_step1_text',
  SSO_MIGRATION_SHELL_ACCOUNT_SERVICES_STEP2:
    'sso_migration_shell_account_services_step2',
  SSO_MIGRATION_SHELL_ACCOUNT_SERVICES_STEP2_TEXT:
    'sso_migration_shell_account_services_step2_text',
  SSO_MIGRATION_SHELL_ACCOUNT_SERVICES_STEP3:
    'sso_migration_shell_account_services_step3',
  SSO_MIGRATION_SHELL_ACCOUNT_SERVICES_STEP3_TEXT:
    'sso_migration_shell_account_services_step3_text',
  SSO_MIGRATION_SHELL_ACCOUNT_SERVICES_TITLE:
    'sso_migration_shell_account_services_title',
  SSO_MIGRATION_VALIDATION_TITLE: 'sso_migration_validation_title',
  SSO_MULTICARD_TYPE_ADAC: 'sso_multicard_type_adac',
  SSO_MULTICARD_TYPE_B2B: 'sso_multicard_type_b2b',
  SSO_MULTICARD_TYPE_B2C: 'sso_multicard_type_b2c',
  SSO_MULTICARD_TYPE_VPOWER: 'sso_multicard_type_vpower',
  SSO_NO_QUESTIONS_FOUND: 'sso_settings_no_questions_found',
  SSO_OVERVIEW_COMPLETE: 'sso_overview_complete',
  SSO_OVERVIEW_CREATE_AN_ACCOUNT_LABEL: 'sso_overview_create_an_account_label',
  SSO_OVERVIEW_MPP_FORGOT_PASSWORD: 'sso_overview_mpp_forgot_password',
  SSO_FORGOTPASSWORDCODE_EXPIRED: 'sso_forgotpasswordcode_expired',
  SSO_OVERVIEW_OR_LABEL: 'sso_overview_or_label',
  SSO_OVERVIEW_REGISTER_BUTTON: 'sso_overview_register_button',
  SSO_OVERVIEW_SIGN_IN_LABEL: 'sso_overview_sign_in_label',
  SSO_PAYONSITE_COMPLETE_SUBSCRIPTION_TEXT:
    'sso_payonsite_complete_subscription_text',
  SSO_PAYONSITE_COMPLETE_SUBSCRIPTION_TITLE:
    'sso_payonsite_complete_subscription_title',
  SSO_PAYONSITE_UNVERIFIED_SUBSCRIPTION_TEXT:
    'sso_payonsite_unverified_subscription_text',
  SSO_PAYONSITE_UNVERIFIED_SUBSCRIPTION_TITLE:
    'sso_payonsite_unverified_subscription_title',
  SSO_PROFILE_ADD_NEW_CARD: 'sso_profile_add_new_card',
  SSO_PROFILE_CHANGE_PASSWORD_LABEL: 'sso_profile_change_password_label',
  SSO_PROFILE_CHANGES_ARE_SAVED: 'sso_profile_changes_are_saved',
  SSO_PROFILE_COMMUNICATIONS: 'sso_profile_communications',
  SSO_PROFILE_DRIVE_SETTINGS: 'sso_profile_drive_settings',
  SSO_PROFILE_EDIT_PROFILE: 'sso_profile_edit_profile',
  SSO_PROFILE_EMAIL_NOTIFICATION_LABEL: 'sso_profile_email_notifications_label',
  SSO_PROFILE_FAILED_TO_UPLOAD: 'sso_profile_failed_to_upload',
  SSO_PROFILE_FAILED_TO_UPLOAD_TEXT: 'sso_profile_failed_to_upload_text',
  SSO_PROFILE_GO_PLUS_SETTINGS_TO_SETUP_BUTTON:
    'sso_profile_go_plus_setting_to_setup_button',
  SSO_PROFILE_GO_PLUS_SETTINGS_TO_SETUP_TEXT:
    'sso_profile_go_plus_setting_to_setup_text',
  SSO_PROFILE_GO_PLUS_SETTINGS_TO_SETUP_TITLE:
    'sso_profile_go_plus_setting_to_setup_title',
  SSO_PROFILE_LOGOUT_FROM_ALL: 'sso_profile_logout_from_all',
  SSO_PROFILE_LOGOUT_FROM_ALL_TEXT: 'sso_profile_logout_from_all_text',
  SSO_PROFILE_LOYALTY_FUTURES: 'sso_profile_loyalty_futures',
  SSO_PROFILE_MOBILE_NOTIFICATIONS_LABEL:
    'sso_profile_mobile_notifications_label',
  SSO_PROFILE_MY_PROFILE_LABEL: 'sso_profile_my_profile_label',
  SSO_PROFILE_NEWSLETTER_TOGGLE: 'sso_profile_newsletter_toggle',
  SSO_PROFILE_OFFERS_NOT_AVAILABLE: 'sso_profile_offers_not_available',
  SSO_PROFILE_OFFERS_NOT_AVAILABLE_TEXT:
    'sso_profile_offers_not_available_text',
  SSO_PROFILE_PERSONAL_DETAILS_LABEL: 'sso_profile_personal_details_label',
  SSO_PROFILE_RECEIVE_NEWSLETTER: 'sso_profile_receive_newsletter',
  SSO_PROFILE_REPLACE_EXISTING_CARD: 'sso_profile_replace_existing_card',
  SSO_PROFILE_SAVE_CHANGES: 'sso_profile_save_changes',
  SSO_PROFILE_SEE_YOU_SOON: 'sso_profile_see_you_soon',
  SSO_PROFILE_SEE_YOU_SOON_8_SECONDS: 'sso_profile_see_you_soon_8_seconds',
  SSO_PROFILE_SEE_YOU_SOON_BUTTON: 'sso_profile_see_you_soon_button',
  SSO_PROFILE_SEE_YOU_SOON_TEXT: 'sso_profile_see_you_soon_text',
  SSO_PROFILE_SHELL_DRIVE_LABEL: 'sso_profile_shell_drive_label',
  SSO_PROFILE_SHELL_DRIVERS_CLUB: 'sso_profile_shell_drivers_club',
  SSO_PROFILE_SHELL_GO_PLUS_LABEL: 'sso_profile_shell_go_plus_label',
  SSO_PROFILE_SHELL_GO_PLUS_COMMS_LABEL:
    'sso_profile_shell_go_plus_comms_label',
  SSO_PROFILE_SHELL_LOYALTY_FUTURES_LABEL:
    'sso_profile_shell_loyalty_futures_label',
  SSO_PROFILE_SHELL_LOYALTY_HEADER: 'sso_profile_shell_loyalty_header',
  SSO_PROFILE_SHELL_LOYALTY_LABEL: 'sso_profile_shell_loyalty_label',
  SSO_PROFILE_SHELL_LOYALTY_TOGGLE: 'sso_profile_shell_loyalty_toggle',
  SSO_PROFILE_SHELL_PAYMENTS_LABEL: 'sso_profile_shell_payments_label',
  SSO_PROFILE_SHELL_PAYMENTS_TOGGLE: 'sso_profile_shell_payments_toggle',
  SSO_PROFILE_SHELL_UPDATES_PHONE: 'sso_profile_shell_updates_phone',
  SSO_PROFILE_SHELL_VOC_LABEL: 'sso_profile_shell_voc_label',
  SSO_PROFILE_SIGN_OUT_ALL_DEVICES: 'sso_profile_sign_out_all_devices',
  SSO_PROFILE_SIGN_OUT_MESSAGE: 'sso_profile_sign_out_message',
  SSO_PROFILE_SIGN_OUT_ONE_DEVICE_BUTTON:
    'sso_profile_sign_out_one_device_button',
  SSO_PROFILE_SNACKBAR_DATA_SAVED_TEXT: 'sso_profile_snackbar_data_saved_text',
  SSO_PROFILE_TURN_OFF: 'sso_profile_turn_off',
  SSO_PROFILE_TURN_ON: 'sso_profile_turn_on',
  SSO_REGISTER_DEVICE_CANNOT_REGISTER: 'sso_register_device_cannot_register',
  SSO_REGISTER_DEVICE_CANNOT_REGISTER_TEXT:
    'sso_register_device_cannot_register_text',
  SSO_REGISTER_EDIT_FIELD: 'sso_register_edit_field',
  SSO_REGISTER_EXISTING_SHELL_USER_TEXT:
    'sso_register_existing_shell_user_text',
  SSO_REGISTER_HEADER_STEP_LABEL: 'sso_register_header_step_label',
  SSO_REGISTER_REVIEW_SUBMIT_LABEL: 'sso_register_review_submit_label',
  SSO_REGISTER_SCREEN1_TITLE: 'sso_register_screen1_title',
  SSO_REGISTER_SCREEN1_TITLE_DESKTOP: 'sso_register_screen1_title_desktop',
  SSO_REGISTER_SCREEN2_TITLE: 'sso_register_screen2_title',
  SSO_REGISTER_SCREEN2_TITLE_DESKTOP: 'sso_register_screen2_title_desktop',
  SSO_REGISTER_SCREEN3_TITLE: 'sso_register_screen3_title',
  SSO_REGISTER_SCREEN4_TITLE: 'sso_register_screen4_title',
  SSO_REPLACE_CARDS_BUTTON: 'sso_replace_cards_button',
  SSO_RESET_PASSWORD_CALL_US: 'sso_reset_password_call_us',
  SSO_RESET_PASSWORD_CALL_US_NUMBER: 'sso_reset_password_call_us_number',
  SSO_RESET_PASSWORD_CALL_US_NUMBER_OPENINGHOURS:
    'sso_reset_password_call_us_number_openinghours',
  SSO_RESET_PASSWORD_CHECK_EMAIL_LABEL:
    'sso_reset_password_password_check_email_label',
  SSO_RESET_PASSWORD_EMAIL_US: 'sso_reset_password_email_us',
  SSO_RESET_PASSWORD_EMAIL_US_ADDRESS: 'sso_reset_password_email_us_address',
  SSO_RESET_PASSWORD_RESET_INFO_LABEL:
    'sso_reset_password_password_reset_info_label',
  SSO_RESET_PASSWORD_RESET_INFO_LABEL_PHONE:
    'sso_reset_password_password_reset_info_label_phone',
  SSO_RESET_PASSWORD_RESET_LABEL: 'sso_reset_password_password_reset_label',
  SSO_RESET_PASSWORD_RESET_PROMPT_BUTTON_NO:
    'sso_reset_password_password_reset_prompt_button_no',
  SSO_RESET_PASSWORD_RESET_PROMPT_BUTTON_YES:
    'sso_reset_password_password_reset_prompt_button_yes',
  SSO_RESET_PASSWORD_RESET_PROMPT_TEXT:
    'sso_reset_password_password_reset_prompt_text',
  SSO_RESET_PASSWORD_RESET_PROMPT_TITLE:
    'sso_reset_password_password_reset_prompt_title',
  SSO_RESET_PASSWORD_SET_LABEL: 'sso_reset_password_password_set_label',
  SSO_RESET_PASSWORD_UPDATED_BUTTON:
    'sso_reset_password_password_updated_button',
  SSO_RESET_PASSWORD_UPDATED_LABEL: 'sso_reset_password_password_updated_label',
  SSO_RESET_PASSWORD_UPDATED_LABEL_FAILED:
    'sso_reset_password_password_updated_label_failed',
  SSO_RESET_PASSWORD_UPDATED_MESSAGE_FAILED_TEXT:
    'sso_reset_password_password_updated_message_failed_text',
  SSO_RESET_PASSWORD_UPDATED_MESSAGE_TEXT:
    'sso_reset_password_password_updated_message_text',
  SSO_SETTINGS_ACCOUNT_DELETED_LABEL: 'sso_settings_account_deleted_label',
  SSO_SETTINGS_ACCOUNT_DELETED_MESSAGE_TEXT:
    'sso_settings_account_deleted_message_text',
  SSO_SETTINGS_DATA_LOST_LABEL: 'sso_settings_data_lost_label',
  SSO_SETTINGS_DATA_LOST_MESSAGE_TEXT: 'sso_settings_data_lost_message_text',
  SSO_SETTINGS_DELETE_ACCOUNT_INFO_TEXT:
    'sso_settings_delete_account_info_text',
  SSO_SETTINGS_DELETE_ACCOUNT_LABEL: 'sso_settings_delete_account_label',
  SSO_SETTINGS_DELETE_MY_ACCOUNT_LABEL: 'sso_settings_delete_my_account_label',
  SSO_SETTINGS_HELP_LABEL: 'sso_settings_help_label',
  SSO_SETTINGS_PRIVACY_POLICY: 'sso_settings_privacy_policy',
  SSO_SETTINGS_SECURITY_SETTINGS: 'sso_settings_security_settings',
  SSO_SETTINGS_SETTINGS_LABEL: 'sso_settings_settings_label',
  SSO_SETTINGS_TERMS_CONDITIONS: 'sso_settings_terms_conditions',
  SSO_SETTINGS_WHAT_IS_NEW: 'sso_settings_what_is_new',
  SSO_SIGN_IN: 'sso_sign_in',
  SSO_SIGN_IN_WITH_EMAIL_TEXT: 'sso_sign_in_with_email_text',
  SSO_STRENGTHEN_SECURITY_UPDATE_TEXT: 'sso_strengthen_security_update_text',
  SSO_TECHNICAL_ERROR_ARCHETYPE: 'sso_technical_error_archetype',
  SSO_TECHNICAL_ERROR_REDIRECT: 'sso_technical_error_redirect',
  SSO_TECHNICAL_ERROR_SCOPES: 'sso_technical_error_scopes',
  SSO_UPDATE_CURRENT_PASSWORD: 'sso_update_current_password',
  SSO_UPDATE_NEW_EMAIL: 'sso_update_new_email',
  SSO_UPDATE_NEW_PASSWORD: 'sso_update_new_password',
  SSO_UPDATE_NEW_PHONE: 'sso_update_new_phone',
  SSO_VERIFY_ACCOUNT_LABEL: 'sso_verify_verify_account_label',
  SSO_VERIFY_ACCOUNT_LABEL_FAILED: 'sso_verify_verify_account_label_failed',
  SSO_VERIFY_ACCOUNT_MESSAGE_NO_EMAIL:
    'sso_verify_verify_account_message_no_email',
  SSO_VERIFY_ACCOUNT_MESSAGE_TEXT: 'sso_verify_verify_account_message_text',
  SSO_VERIFY_ACCOUNT_MESSAGE_TEXT_TPDS:
    'sso_verify_verify_account_message_text_tpds',
  SSO_VERIFY_CHANGE_PHONE_BUTTON: 'sso_verify_change_phone_button',
  SSO_VERIFY_CHANGE_PHONE_HEADER: 'sso_verify_change_phone_header',
  SSO_VERIFY_CHANGE_PHONE_NUMBER: 'sso_verify_change_phone_number',
  SSO_VERIFY_CODE_RECEIVED: 'sso_verify_verify_code_received',
  SSO_VERIFY_CONFIRMATION_GO_PLUS_TEXT: 'sso_verify_confirmation_goplus_text',
  SSO_VERIFY_EDIT_CARD: 'sso_verify_edit_card',
  SSO_VERIFY_EDIT_EMAIL_BUTTON: 'sso_verify_edit_email_button',
  SSO_VERIFY_EDIT_EMAIL_HEADER: 'sso_verify_edit_email_header',
  SSO_VERIFY_EMAIL_CHANGE_INVALID_TEXT: 'sso_verify_email_change_invalid_text',
  SSO_VERIFY_EMAIL_CHANGE_INVALID_TITLE:
    'sso_verify_email_change_invalid_title',
  SSO_VERIFY_EMAIL_LABEL: 'sso_verify_verify_email_label',
  SSO_VERIFY_EMAIL_LINK_FAILED: 'sso_verify_verify_email_link_failed',
  SSO_VERIFY_EMAIL_VERIFIED_BUTTON: 'sso_verify_verify_email_verified_button',
  SSO_VERIFY_EMAIL_VERIFIED_CONTINUE_IN_BROWSER_LINK:
    'sso_verify_verify_email_verified_continueinbrowser_link',
  SSO_VERIFY_EMAIL_VERIFIED_MGM_TEXT:
    'sso_verify_verify_email_verified_membergetmember_text',
  SSO_VERIFY_EMAIL_VERIFIED_TEXT: 'sso_verify_verify_email_verified_text',
  SSO_VERIFY_GO_PLUS_ERROR_EMAIL_CONFIRMED_TEXT:
    'sso_verify_go_plus_error_email_confirmed_text',
  SSO_VERIFY_GO_PLUS_ERROR_EMAIL_CONFIRMED_TITLE:
    'sso_verify_go_plus_error_email_confirmed_title',
  SSO_VERIFY_LINK_FAILED: 'sso_verify_verify_link_failed',
  SSO_VERIFY_VERIFY_PHONE_FAILED: 'sso_verify_verify_phone_failed',
  SSO_VERIFY_PHONE_TEXT: 'sso_verify_verify_phone_text',
  SSO_VERIFY_PHONE_TITLE: 'sso_verify_verify_phone_title',
  SSO_VERIFY_VERIFY_PHONE_VERIFIED_TEXT:
    'sso_verify_verify_phone_verified_text',
  SSO_VERIFY_VERIFY_PHONE_VERIFIED_CONTINUEINBROWSER_TEXT:
    'sso_verify_verify_phone_verified_continueinbrowser_text',
  SSO_VERIFY_SEND_AGAIN: 'sso_verify_send_again',
  SSO_VERIFY_SETUP_GO_PLUS_LOGIN_TEXT: 'sso_verify_setup_goplus_login_text',
  SSO_VERIFY_SETUP_GO_PLUS_LOGIN_TITLE: 'sso_verify_setup_goplus_login_title',
  SSO_VERIFY_WRONG_EMAIL_LINK: 'sso_verify_verify_wrong_email_link',
  SSO_WELCOME_SCREEN_TEXT: 'sso_welcome_screen_text',
  SSO_WELCOME_SCREEN_TITLE: 'sso_welcome_screen_title',
  SSO_WIDGET_PAGE_NAME_LOGGED_IN: 'sso_widget_page_name_logged_in',
  SSO_WIDGET_PAGE_NAME_LOGGED_OUT: 'sso_widget_page_name_logged_out',
  SCOPES_PERMISSIONS_TITLE: 'scopes_permissions_title',
  SCOPES_PERMISSIONS_BODY: 'scopes_permissions_body',
  SCOPES_PERMISSIONS_FOOTER: 'scopes_permissions_footer',
  SCOPES_PERMISSIONS_PROMPT_TITLE: 'scopes_permissions_prompt_title',
  SCOPES_PERMISSIONS_PROMPT_BODY: 'scopes_permissions_prompt_body',
  SCOPES_PERMISSIONS_PROMPT_DECLINE_BUTTON:
    'scopes_permissions_prompt_decline_button',
  SCOPES_PERMISSIONS_PROMPT_CANCEL_BUTTON:
    'scopes_permissions_prompt_cancel_button',
  SCOPES_PERMISSIONS_REVIEW: 'scopes_permissions_review',
  SCOPES_ACCEPTED_BODY: 'scopes_accepted_body',
  SCOPES_ACCEPTED_TITLE: 'scopes_accepted_title',
  SCOPES_ACCEPT_BUTTON: 'accept_button',
  SCOPES_DECLINE_BUTTON: 'decline_button',
  TWOFA_ACTIVATION_TITLE: '2fa_activation_title',
  TWOFA_ACTIVATION_BODY: '2fa_activation_body',
  TWOFA_ACTIVATION_BUTTON_ACCEPT: '2fa_activation_button_accept',
  TWOFA_ACTIVATION_BUTTON_DECLINE: '2fa_activation_button_decline',
  TWOFA_ADD_PHONE_VERIFY_BUTTON: '2fa_add_phone_verify_button',
  TWOFA_ADD_PHONE_TITLE: '2fa_add_phone_title',
  TWOFA_ADD_PHONE_BODY: '2fa_add_phone_body',
  TWOFA_OTP_TITLE: '2fa_otp_title',
  TWOFA_OTP_BODY: '2fa_otp_body',
  TWOFA_OTP_BODY_LOGIN: '2fa_otp_body_login',
  TWOFA_OTP_TROUBLE_LINK: '2fa_otp_trouble_link',
  TWOFA_OTP_VERIFY_BUTTON: '2fa_otp_verify_button',
  TWOFA_OTP_PRIMARY_DEVICE: '2fa_otp_primary_device_body',
  TWOFA_OTP_ERROR_SEND: '2fa_otp_error_send',
  TWOFA_OTP_ERROR_VERIFY: '2fa_otp_error_verify',
  TWOFA_SUCCESS_TITLE: '2fa_success_title',
  TWOFA_SUCCESS_BODY: '2fa_success_body',
  TWOFA_SUCCESS_DONE_BUTTON: '2fa_success_done_button',
  PROFILE_SECURITY_TWOFA_TOGGLE_LABEL: 'profile_security_2fa_toggle_label',
  PROFILE_SECURITY_TWOFA_OFF: 'profile_security_2fa_off',
  PROFILE_SECURITY_TWOFA_ON: 'profile_security_2fa_on',
  PROFILE_SECURITY_TWOFA_EXPLAINER_MESSAGE_OFF:
    'profile_security_2fa_explainer_message_off',
  PROFILE_SECURITY_TWOFA_EXPLAINER_MESSAGE_ON:
    'profile_security_2fa_explainer_message_on',
  PROFILE_SECURITY_TITLE: 'profile_security_title',
  TWOFA_DISABLE_PASSWORD_VERIFICATION_TITLE:
    '2fa_disable_password_verification_title',
  TWOFA_DISABLE_PASSWORD_VERIFICATION_BODY:
    '2fa_disable_password_verification_body',
  TWOFA_DISABLE_PASSWORD_VERIFICATION_BUTTON:
    '2fa_disable_password_verification_button',
  BLOCKED_FOREVER_ERROR_LABEL: 'blocked_forever_error_label',
  BLOCKED_FOREVER_ERROR_MESSAGE: 'blocked_forever_error_message',
  BLOCKED_FOREVER_ERROR_BUTTON_PRIMARY: 'blocked_forever_error_button_primary',
  BLOCKED_FOREVER_ERROR_BUTTON_SECONDARY:
    'blocked_forever_error_button_secondary',
  PROGRESSIVE_PROFILING_ONBOARDING_HEADER:
    'progressive_profiling_onboarding_header',
  PROGRESSIVE_PROFILING_ONBOARDING_PARAGRAPH:
    'progressive_profiling_onboarding_paragraph',
  PROGRESSIVE_PROFILING_MAIL_TITLE: 'progressive_profiling_mail_title',
  PROGRESSIVE_PROFILING_MAIL_BODY: 'progressive_profiling_mail_body',
  PROGRESSIVE_PROFILING_MAIL_CTA: 'progressive_profiling_mail_cta',
  PROGRESSIVE_PROFILING_MAIL_SNACKBAR_MESSAGE:
    'progressive_profiling_mail_snackbar_message',
  SCOPES_ERROR_OTP_EXPIRED_TITLE: 'scopes_error_otp_expired_title',
  SCOPES_ERROR_OTP_EXPIRED_BODY: 'scopes_error_otp_expired_body',
  SCOPES_ERROR_OTP_EXPIRTED_CTA: 'scopes_error_otp_expired_cta',
  PROGRESSIVE_PROFILING_ONBOARDING_CARD_TITLE_1:
    'progressive_profiling_onboarding_card_title_1',
  PROGRESSIVE_PROFILING_ONBOARDING_CARD_TITLE_2:
    'progressive_profiling_onboarding_card_title_2',
  PROGRESSIVE_PROFILING_ONBOARDING_CARD_TITLE_3:
    'progressive_profiling_onboarding_card_title_3',
  PROGRESSIVE_PROFILING_ONBOARDING_CARD_BODY_1:
    'progressive_profiling_onboarding_card_body_1',
  PROGRESSIVE_PROFILING_ONBOARDING_CARD_BODY_2:
    'progressive_profiling_onboarding_card_body_2',
  PROGRESSIVE_PROFILING_ONBOARDING_CARD_BODY_3:
    'progressive_profiling_onboarding_card_body_3',
  PROGRESSIVE_PROFILING_ONBOARDING_CARD_CTA:
    'progressive_profiling_onboarding_card_cta',
  PROGRESSIVE_PROFILING_COMPLETE_PROFILE_TITLE:
    'progressive_profiling_complete_profile_title',
  PROGRESSIVE_PROFILING_COMPLETE_PROFILE_BODY:
    'progressive_profiling_complete_profile_body',
  PROGRESSIVE_PROFILING_DYNAMIC_FORM_SUCCESS_SCREEN_TITLE:
    'progressive_profiling_dynamic_form_success_screen_title',
  PROGRESSIVE_PROFILING_DYNAMIC_FORM_SUCCESS_SCREEN_BODY:
    'progressive_profiling_dynamic_form_success_screen_body',
  PROGRESSIVE_PROFILING_COMPLETE_PROFILE_CTA_PRIMARY:
    'progressive_profiling_complete_profile_cta_primary',
  PROGRESSIVE_PROFILING_DYNAMIC_FORM_BACK_CTA:
    'progressive_profiling_dynamic_form_back_cta',
  PROGRESSIVE_PROFILING_ACCOUNT_SUCCESSFULLY_VERIFIED:
    'progressive_profiling_account_successfully_verified',
  SSO_REGISTRATION_STEPS_PARTIAL: 'sso_registration_steps_partial',
  SSO_UPDATED_TERMS_CONDITIONS_GOPLUS_TITLE:
    'sso_updated_terms_conditions_goplus_title',
  SSO_UPDATED_TERMS_CONDITIONS_GOPLUS_SUBTITLE:
    'sso_updated_terms_conditions_goplus_subtitle',
  SSO_UPDATED_TERMS_CONDITIONS_GOPLUS_TC:
    'sso_updated_terms_conditions_goplus_tc',
  SSO_UPDATED_TERMS_CONDITIONS_GOPLUS_BUTTON:
    'sso_updated_terms_conditions_goplus_button',
  SSO_UPDATED_TERMS_CONDITIONS_GOPLUS_NEWSLETTERS:
    'sso_updated_terms_conditions_goplus_newsletters',
  SSO_FORMS_AIRMILES_EMAIL_TITLE: 'sso_forms_airmiles_email_title',
  SSO_FORMS_AIRMILES_PERSONAL_DATA_TITLE:
    'sso_forms_airmiles_personaldata_title',
  SSO_FORMS_AIRMILES_ADDRESS_TITLE: 'sso_forms_airmiles_address_title',
  SSO_FORMS_AIRMILES_CONTINUE_TC_PP: 'sso_forms_airmiles_continue_tc_pp',
  SSO_FORMS_AIRMILES_REGISTRATION_CARD_TITLE:
    'sso_forms_airmiles_registration_card_title',
  SSO_FORMS_AIRMILES_REGISTRATION_CARD_BODY:
    'sso_forms_airmiles_registration_card_body',
  SSO_FORMS_AIRMILES_LOADING_TITLE: 'sso_forms_airmiles_loading_title',
  SSO_FORMS_AIRMILES_LOADING_BODY: 'sso_forms_airmiles_loading_body',
  SSO_FORMS_AIRMILES_ERROR_SNACKBAR: 'sso_forms_airmiles_error_snackbar',
  SSO_FORMS_AIRMILES_COMMS_TITLE: 'sso_forms_airmiles_comms_title',
  SSO_FORMS_AIRMILES_COMMS_SUBTITLE: 'sso_forms_airmiles_comms_subtitle',
  SSO_FORMS_AIRMILES_COMMS_BODY: 'sso_forms_airmiles_comms_body',
  SSO_FORMS_AIRMILES_COMMS_CANCEL: 'sso_forms_airmiles_comms_cancel',
  SSO_FORMS_AIRMILES_COMMS_CONTINUE: 'sso_forms_airmiles_comms_continue',
  SSO_FORMS_AIRMILES_LOGIN_TITLE: 'sso_forms_airmiles_login_title',
  SSO_FORMS_AIRMILES_LOGIN_BODY: 'sso_forms_airmiles_login_body',
  SSO_FORMS_AIRMILES_LOGIN_TC_PP: 'sso_forms_airmiles_login_tc_pp',
  SSO_FORMS_AIRMILES_CONFIRMATION_TITLE:
    'sso_forms_airmiles_confirmation_title',
  SSO_FORMS_AIRMILES_CONFIRMATION_BODY: 'sso_forms_airmiles_confirmation_body',
  SSO_FORMS_AIRMILES_CONFIRMATION_NEXT_BUTTON:
    'sso_forms_airmiles_confirmation_next_button',
  SSO_FORMS_AIRMILES_CONNECTING_TITLE: 'sso_forms_airmiles_connecting_title',
  SSO_FORMS_AIRMILES_CONNECTING_BODY: 'sso_forms_airmiles_connecting_body',
  SSO_FORMS_AIRMILES_ALREADY_CONNECTED_TITLE:
    'sso_forms_airmiles_already_connected_title',
  SSO_FORMS_AIRMILES_ALREADY_CONNECTED_BODY:
    'sso_forms_airmiles_already_connected_body',
  SSO_FORMS_AIRMILES_NOTSYNCHRO_TITLE: 'sso_forms_airmiles_notsynchro_title',
  SSO_FORMS_AIRMILES_NOTSYNCHRO_BODY: 'sso_forms_airmiles_notsynchro_body',
  SSO_CONSENTS_POPUP_TITLE: 'sso_consents_popup_title',
  SSO_CONSENTS_POPUP_SUBTITLE: 'sso_consents_popup_subtitle',
  SSO_CONSENTS_POPUP_TITLE2: 'sso_consents_popup_title2',
  SSO_CONSENTS_POPUP_SECTION1: 'sso_consents_popup_section1',
  SSO_CONSENTS_POPUP_SECTION2: 'sso_consents_popup_section2',
  SSO_CONSENTS_POPUP_SECTION3: 'sso_consents_popup_section3',
  SSO_CONSENTS_POPUP_SECTION4: 'sso_consents_popup_section4',
  SSO_CONSENTS_POPUP_SECTION_BODY: 'sso_consents_popup_section_body',
  SSO_ACCEPT_CONSENTS_POPUP: 'sso_accept_consents_popup',
  SSO_DECLINE_CONSENTS_POPUP: 'sso_decline_consents_popup',
  SSO_GENERAL_HELPFUL_UPDATE_TITLE: 'sso_general_helpful_updates_title',
  SSO_MARKETINGCOMMS_BODY: 'sso_marketingcomms_body',
  SSO_MARKETINGCOMMS_PP: 'sso_marketingcomms_pp',
  SSO_PROFILE_SHELL_DRIVERS_CLUB_EXPANDABLE_TEXT:
    'sso_profile_shell_drivers_club_expandable_text',
  SSO_SEE_MORE_BUTTON: 'sso_see_more_button',
  SSO_SEE_LESS_BUTTON: 'sso_see_less_button',
  SSO_REGISTER_SCREEN_VEHICLE_PREFERENCE_TITLE:
    'sso_register_screen_vehicle_preference_',
  SSO_REGISTER_SCREEN_VEHICLE_PREFERENCE_DESCRIPTION:
    'sso_register_screen_vehicle_preference_description',
  SSO_CUSTOM_TYPE_FUEL: 'sso_custtype_fuel',
  SSO_CUSTOM_TYPE_ELECTRIC: 'sso_custtype_electric',
  SSO_CUSTOM_TYPE_HYBRID: 'sso_custtype_hybrid',
  SSO_CUSTOM_TYPE_PLUGIN_HYBRID: 'sso_custtype_plugin',
  SSO_PROFILE_VEHICLE_PREFERENCES_LABEL:
    'sso_profile_vehicle_preferences_label',
  EV_VEHICLE_PREFERENCE_BODY: 'ev_vehicle_preference_body',
  EV_VEHICLE_PREFERENCE_PRIMARY_CTA: 'ev_vehicle_preference_primary_cta',
  EV_VEHICLE_PREFERENCE_SUCCESS_SNACKBAR:
    'ev_vehicle_preference_success_snackbar',
  EV_VEHICLE_PREFERENCES_ERROR_SNACKBAR: 'ev_vehicle_preference_error_snackbar',
  SSO_FORMS_ENTER_CARD_TITLE: 'sso_forms_enter_card_title',
  SSO_FORMS_ENTER_CARD_BODY: 'sso_forms_enter_card_body',
  SSO_FORMS_ENTER_EMAIL_TITLE: 'sso_forms_enter_email_title',
  SSO_FORMS_ENTER_EMAIL_BODY_TEXT: 'sso_forms_enter_email_body_text',
  SSO_FORMS_EMAIL_ADDRESS: 'sso_forms_email_address',
  SSO_FORMS_FORGOT_EMAIL_DESCRIPTION_MOBILE:
    'sso_forms_forgot_email_description_mobile',
  SSO_FORMS_FORGOT_EMAIL_DESCRIPTION_WEB:
    'sso_forms_forgot_email_description_web',
  SSO_FORMS_CONTACT_LIVE_CHAT: 'sso_forms_contact_live_chat',
  SSO_FORMS_CONTACT_CUSTOMER_SERVICE: 'sso_forms_contact_customer_service',
  SSO_FORMS_CLUBSMART_CARD_EMAIL_SENT_DESCRIPTION:
    'sso_forms_clubsmart_card_email_sent_description',
  SSO_LOYALTY_CARD_ATTACHED_TITLE: 'sso_loyalty_card_attached_title',
  SSO_LOYALTY_CARD_ATTACHED_BODY_TEXT: 'sso_loyalty_card_attached_body_text',
  SSO_SIGNIN_BLOCKED_SCREEN_TITLE: 'sso_signin_blocked_screen_title',
  SSO_SIGNIN_BLOCKED_SCREEN_BODY: 'sso_signin_blocked_screen_body',
  SSO_SIGNIN_BLOCKED_SCREEN_MOBILE_BUTTON:
    'sso_signin_blocked_screen_mobile_button',
  SSO_SIGNIN_BLOCKED_SCREEN_DESKTOP_BUTTON:
    'sso_signin_blocked_screen_desktop_button',
  SSO_SOL_MIGRATION_RESEND_EMAIL: 'sso_sol_migration_resend_email',
  SSO_ADDITIONAL_PHONE_NUMBER_UNVERIFIED_WARNING_TEXT: 'sso_additional_phone_number_unverified_warning_text',
  SSO_ADDITIONAL_PHONE_NUMBER_EMPTY_WARNING_TEXT:'sso_additional_phone_number_empty_warning_text',
  SSO_REGISTRATION_OPTIONS_MODAL_TITLE:'sso_registration_options_modal_title',
  SSO_REGISTRATION_OPTIONS_MODAL_BODY:'sso_registration_options_modal_body',
  SSO_REGISTRATION_OPTIONS_MODAL_EMAIL_BUTTON:'sso_registration_options_modal_email_button',
  SSO_LOGIN_SOCIAL_LOGIN_OPTIONS_LABEL:'sso_login_social_login_options_label',
  SSO_SOCIAL_LOGIN_APPLE_LABEL:'sso_social_login_apple_label',
  SSO_SOCIAL_LOGIN_GOOGLE_LABEL:'sso_social_login_google_label',
  SSO_LOGIN_SIGN_IN_OPTION_APPLE:'sso_login_sign_in_option_apple',
  SSO_LOGIN_SIGN_IN_OPTION_GOOGLE:'sso_login_sign_in_option_google',
  SSO_LOGIN_SIGN_IN_OPTION_EMAIL:'sso_login_sign_in_option_email',
  SSO_REGISTER_OPTION_APPLE:'sso_register_option_apple',
  SSO_REGISTER_OPTION_GOOGLE:'sso_register_option_google',
  SSO_REGISTER_OPTION_EMIAL:'sso_register_option_email'
};
export default lokaliseTags;
