import {
  REGISTER_PAGE,
  EDIT_PROFILE_PAGE,
  FORGOT_PASSWORD_PAGE,
  RESET_PASSWORD_PAGE,
  LOGIN_PAGE,
  CHANGE_EMAIL_PAGE,
  CHANGE_PASSWORD_PAGE,
  VERIFY_USER_PAGE,
  LOYALTY_INITIAL_PAGE,
  LOYALTY_FUTURES_CARD_INPUT_PAGE,
  SOL_MIGRATION_PAGE,
  SOL_PAYONSITE_DETAILS_PAGE,
  LOYALTY_FUTURES_MIGRATION_ID_PAGE,
  MIGRATION_PASSWORD_PAGE,
} from './formPages';
import lokaliseTags from './lokaliseTags';
import { ANDROID_APP, IOS_APP } from '../features/notification/utils';

const homeTagText = 'SSO | Welcome Initial Screen';
const homeTags = {
  pageLoad: {
    pageName: homeTagText,
  },
  goToRegister: {
    pageName: homeTagText,
    clickarea: homeTagText,
    cta: lokaliseTags.SSO_OVERVIEW_REGISTER_BUTTON,
  },
  goToLogin: {
    pageName: homeTagText,
    clickarea: homeTagText,
    cta: lokaliseTags.SSO_OVERVIEW_SIGN_IN_LABEL,
  },
};

const ssoRegisterTagText = 'SSO | Welcome Initial Screen'
const ssoRegisterClickArea = 'SSO | Registration | Registration option modal'
const ssoRegisterTags = {
  appleResgisterSignIn: {
    pageName: ssoRegisterTagText,
    clickarea: ssoRegisterClickArea,
    cta: lokaliseTags.SSO_REGISTER_OPTION_APPLE
  },
  googleRegisterSignIn: {
    pageName: ssoRegisterTagText,
    clickarea: ssoRegisterClickArea,
    cta: lokaliseTags.SSO_REGISTER_OPTION_GOOGLE
  },
  emailRegisterSignIn: {
    pageName: ssoRegisterTagText,
    clickarea: ssoRegisterClickArea,
    cta: lokaliseTags.SSO_REGISTER_OPTION_EMIAL
  }
}

const ssoLoginTagText = 'SSO | Sign In'
const ssoLoginTags = {
  appleSignIn: {
    pageName: ssoLoginTagText,
    clickarea: ssoLoginTagText,
    cta: lokaliseTags.SSO_LOGIN_SIGN_IN_OPTION_APPLE,
  },
  googleSignIn: {
    pageName: ssoLoginTagText,
    clickarea: ssoLoginTagText,
    cta: lokaliseTags.SSO_LOGIN_SIGN_IN_OPTION_GOOGLE,
  },
  emailSignIn: {
    pageName: ssoLoginTagText,
    clickarea: ssoLoginTagText,
    cta: lokaliseTags.SSO_LOGIN_SIGN_IN_OPTION_EMAIL,
  }
}

const REGISTER_ADOBE_TAG_PAGENAME = 'SSO | Registration';
const REGISTER_ADOBE_TAG_TERMS_CONDITIONS =
  'SSO | Registration TermsConditions';
const SSO_REGISTRATION_CONFIRM_EMAIL = 'SSO | Registration Confirm Email';
const SSO_REGISTRATION_COMPLETE = 'SSO | Registration Complete';

const registerAdobeTags = {
  tag: {
    pageName: REGISTER_ADOBE_TAG_PAGENAME,
  },
  clickTCAndPPLink: {
    pageName: 'sso_general_tc_pp',
    clickarea: REGISTER_ADOBE_TAG_PAGENAME,
    cta: 'sso_general_tc_pp',
  },
  ctaTag: {
    pageName: REGISTER_ADOBE_TAG_PAGENAME,
    cta: lokaliseTags.SSO_GENERAL_NEXT_LABEL,
    pushState: null,
  },
  addressTags: {
    pageName: 'SSO | Registration Address',
    clickarea: 'SSO | Registration Address',
    cta: lokaliseTags.SSO_GENERAL_NEXT_LABEL,
  },
  VerifyEmail: {
    pageName: SSO_REGISTRATION_CONFIRM_EMAIL,
    status: 'Logged Out',
  },
  openEmailAppCTA: {
    pageName: SSO_REGISTRATION_CONFIRM_EMAIL,
    cta: lokaliseTags.SSO_GENERAL_OPEN_EMAIL_BUTTON,
  },
  errorConfirmEmail: {
    pageName: SSO_REGISTRATION_CONFIRM_EMAIL,
    cta: lokaliseTags.SSO_VERIFY_WRONG_EMAIL_LINK,
  },
  registrationComplete: {
    pageName: SSO_REGISTRATION_COMPLETE,
  },
  registrationCompleteCTA: {
    pageName: SSO_REGISTRATION_COMPLETE,
    cta: lokaliseTags.SSO_GENERAL_BACK,
    EventAction: 'Click',
    EventDetails: 'Continue in browser',
  },
  registrationWallet: {
    pageName: SSO_REGISTRATION_COMPLETE,
    EventAction: 'Click',
    EventDetails: 'Add to wallet',
  },
  termsAndConditions: {
    pageName: REGISTER_ADOBE_TAG_TERMS_CONDITIONS,
  },
  termsAndConditionsBackArrow: {
    pageName: REGISTER_ADOBE_TAG_TERMS_CONDITIONS,
    clickarea: REGISTER_ADOBE_TAG_TERMS_CONDITIONS,
    cta: lokaliseTags.SSO_GENERAL_BACK,
  },
};

const loginAdobeTags = {
  tag: {
    pageName: 'SSO | Sign In',
  },
  signInOptionCardNumber: {
    pageName: 'SSO | Sign In',
    cta: lokaliseTags.SSO_LOGIN_SIGN_IN_OPTION_CARD_NUMBER,
  },
  ctaForgotPassword: {
    pageName: 'SSO | Sign In Reset Password',
  },
  resendEmailConfirmation: {
    pageName: 'SSO | Sign In Confirm Email',
  },
  openEmailAppCTA: {
    pageName: 'SSO | Sign In Confirm Email',
    cta: lokaliseTags.SSO_GENERAL_OPEN_EMAIL_BUTTON,
  },
};

const editProfileAdobeTag = {
  tag: {
    pageName: 'SSO | Edit Details',
  },
  cta: {
    pageName: 'SSO | Edit Details',
  },
};

const forgotPassword = {
  tag: {
    pageName: 'SSO | Forgot Password',
  },
  cta: {
    pageName: 'SSO | Forgot Password Enter Email',
    cta: lokaliseTags.SSO_GENERAL_NEXT_LABEL,
  },
  checkEmail: {
    pageName: 'SSO | Forgot Password Check Email',
  },
  openEmailAppCTA: {
    pageName: 'SSO | Forgot Password Check Email',
  },
};

const resetPassword = {
  tag: {
    pageName: 'SSO | Forgot Password Create New Password',
  },
  cta: {
    pageName: 'SSO | Forgot Password Create New Password',
    cta: lokaliseTags.SSO_GENERAL_SAVE_LABEL,
  },
  confirmationNotificationLoad: {
    pageName: 'SSO | Forgot Password New Password Confirmed',
  },
  confirmationNotificationCTA: {
    pageName: 'SSO | Forgot Password New Password Confirmed',
    cta: lokaliseTags.SSO_RESET_PASSWORD_UPDATED_BUTTON,
  },
};

const resetPasswordSecurityUpdate = {
  tag: {
    pageName: 'SSO | Strengthen Security Update Screen',
  },
  cta: {
    pageName: 'SSO | Strengthen Security Update Screen',
    cta: 'security update get started',
  },
};

const changeEmailAdobeTags = {
  tag: {
    pageName: 'SSO | Registration Change email',
  },
  cta: {
    pageName: 'SSO | Registration Change email',
    clickarea: 'SSO | Registration Wrong email',
    cta: lokaliseTags.SSO_VERIFY_EDIT_EMAIL_BUTTON,
  },
};

const consentsAdobeTags = {
  tag: {
    [REGISTER_PAGE]: {
      pageName: 'SSO | Registration Consent',
    },
  },
  ClickButtonConsentsEvent: {
    pageName: 'SSO | Registration Consent',
  },
};

const changePassText = 'SSO | Change Password';
const changePasswordTags = {
  pageLoad: {
    pageName: changePassText,
  },
  confirmationPageLoad: {
    pageName: changePassText + ' Saved',
  },
  savePassword: {
    pageName: changePassText,
    cta: lokaliseTags.SSO_GENERAL_SAVE_LABEL,
  },
  resetPassword: {
    pageName: changePassText,
    cta: lokaliseTags.SSO_LOGIN_RESET_PASSWORD_LABEL,
  },
  changePasswordFinished: {
    pageName: changePassText + ' Saved',
    cta: lokaliseTags.SSO_GENERAL_CONTINUE,
  },
};

const verifyUserPageTags = {
  pageLoad: {
    pageName: changePassText,
  },
};

const accountLockedTags = {
  accountLockedClose: {
    pageName: 'SSO | Account Locked',
    cta: lokaliseTags.SSO_GENERAL_CLOSE,
  },
  accountLocked: {
    pageName: 'SSO | Account Locked',
  },
};

const solPageName = 'SSO | Sign in with card id';
const solMigrationCard = {
  pageLoad: {
    pageName: solPageName,
  },
  cta: {
    pageName: solPageName,
    cta: lokaliseTags.SSO_OVERVIEW_SIGN_IN_LABEL,
  },
  forgotPassword: {
    pageName: solPageName,
    cta: lokaliseTags.SSO_RESET_PASSWORD_RESET_LABEL,
  },
};

const solMigrationOverviewtext = 'SSO | Email Migration security update screen';
const solMigrationOverview = {
  pageLoad: {
    pageName: solMigrationOverviewtext,
  },
  cta: {
    pageName: solMigrationOverviewtext,
    cta: lokaliseTags.SSO_MIGRATION_CONTINUE_BUTTON,
  },
};

const solFuelCardDialogtext = 'SSO | Fuelcard dialog';
const solFuelCardDialog = {
  pageLoad: {
    pageName: solFuelCardDialogtext,
  },
  ctaYes: {
    pageName: solFuelCardDialogtext,
    cta: lokaliseTags.SSO_ATTACH_CARD_FUELCARD_DIALOG_YES,
  },
  ctaNo: {
    pageName: solFuelCardDialogtext,
    cta: lokaliseTags.SSO_ATTACH_CARD_FUELCARD_DIALOG_NO,
  },
};

const solMigrationEmailtext = 'SSO | Email Migration enter your email screen';
const solMigrationEmailtext2 = 'SSO | Email already exist dialog';
const solMigrationEmail = {
  pageLoad: {
    pageName: solMigrationEmailtext,
  },
  cta: {
    pageName: solMigrationEmailtext,
    cta: lokaliseTags.SSO_GENERAL_NEXT_LABEL,
  },
  toggledConsent: {
    pageName: solMigrationEmailtext,
    cta: (name, state) => `sso_profile_shell_${name}_toggle_${state}`,
  },
  link: {
    pageName: solMigrationEmailtext,
    cta: lokaliseTags.SSO_GENERAL_TC_PRIVACY_POLICY,
  },
  popupEmailExists: {
    pageLoad: {
      pageName: solMigrationEmailtext2,
    },
    cancelButton: {
      pageName: solMigrationEmailtext2,
      cta: lokaliseTags.SSO_GENERAL_CANCEL_BUTTON,
    },
    signInButton: {
      pageName: solMigrationEmailtext2,
      cta: lokaliseTags.SSO_MIGRATION_CONFIRM_EMAIL_PROMPT_SIGN_IN,
    },
  },
};

const solMigrationPasswordtext =
  'SSO | Email Migration enter your password screen';
const solMigrationPassword = {
  pageLoad: {
    pageName: solMigrationPasswordtext,
  },
  cta: {
    pageName: solMigrationPasswordtext,
    cta: lokaliseTags.SSO_GENERAL_NEXT_LABEL,
  },
  link: {
    pageName: solMigrationPasswordtext,
    cta: lokaliseTags.SSO_GENERAL_GOOGLE_CAPTCHA_TEXT,
  },
};

const solMigrationConfirmationtext =
  'SSO | Email Migration Confirmation screen';
const solMigrationConfirmation = {
  pageLoad: {
    pageName: solMigrationConfirmationtext,
  },
  cta: {
    pageName: solMigrationConfirmationtext,
    cta: lokaliseTags.SSO_MIGRATION_CONTINUE_BUTTON,
  },
};

const solSmartDealDialogtext = 'SSO | Smartdeal complete details dialog';
const solSmartDealDialog = {
  pageLoad: {
    pageName: solSmartDealDialogtext,
  },
  cancelButton: {
    pageName: solSmartDealDialogtext,
    cta: 'cancel',
  },
  continueButton: {
    pageName: solSmartDealDialogtext,
    cta: 'continue',
  },
};

const solPayOnSiteForm = {
  pageLoad: {
    pageName: 'SSO | Smartdeal address details',
  },
  cta: {
    pageName: 'SSO | Smartdeal complete details dialog',
    cta: 'next',
  },
};

const solPayOnSiteErrortext = 'SSO | Payonsite error';
const solPayOnSiteError = {
  pageLoad: {
    pageName: solPayOnSiteErrortext,
  },
  cta: {
    pageName: solPayOnSiteErrortext,
    cta: lokaliseTags.SSO_GENERAL_GO_BACK,
  },
};

const solPreAttachScreentext = 'SSO | Pre-attach Card Screen';
const solPreAttachScreen = {
  pageLoad: {
    pageName: solPreAttachScreentext,
  },
  attachButton: {
    pageName: solPreAttachScreentext,
    cta: lokaliseTags.SSO_ATTACH_CARD_BUTTON,
  },
  continueButton: {
    pageName: solPreAttachScreentext,
    cta: lokaliseTags.SSO_ATTACH_CONTINUE_BUTTON,
  },
};

const solCardInputtext = 'SSO | Attach loyalty card';
const solCardInput = {
  pageLoad: {
    pageName: solCardInputtext,
  },
  helpButton: {
    pageName: solCardInputtext,
    cta: lokaliseTags.SSO_ATTACH_LOYALTY_CARD_HELP_BUTTON,
  },
  continueButton: {
    pageName: solCardInputtext,
    cta: lokaliseTags.SSO_ATTACH_LOYALTY_CARD_NEXT_BUTTON,
  },
  errorInputCardLink: {
    pageName: solCardInputtext,
    erroMessage: lokaliseTags.SSO_FORMS_ERRORS_CARDID_CANNOT_ADD_EXTRA_CARD,
  },
};

const solCardDialogInfotext = 'SSO | Card info dialog';
const solCardDialogInfo = {
  pageLoad: {
    pageName: solCardDialogInfotext,
  },
  cta: {
    pageName: solCardDialogInfotext,
    cta: lokaliseTags.SSO_GENERAL_NEXT,
  },
};

const solCardNotSupportedt = 'SSO | Card not supported';
const solCardNotSupported = {
  pageLoad: {
    pageName: solCardNotSupportedt,
  },
  cta: {
    pageName: solCardNotSupportedt,
    cta: lokaliseTags.SSO_GENERAL_GO_BACK,
  },
};

const solCardMigratedt = 'SSO | Card linked to other SSO id';
const solCardMigrated = {
  pageLoad: {
    pageName: solCardMigratedt,
  },
  cancelButton: {
    pageName: solCardMigratedt,
    cta: lokaliseTags.SSO_GENERAL_CANCEL_BUTTON,
  },
  signInButton: {
    pageName: solCardMigratedt,
    cta: 'Go to Login button',
  },
};

const solMergeCardst = 'SSO | Merge cards dialog';
const solMergeCards = {
  pageLoad: {
    pageName: solMergeCardst,
  },
  cancelButton: {
    pageName: 'SSO | Merge cards dialog"',
    cta: lokaliseTags.SSO_GENERAL_CANCEL_BUTTON,
  },
  signInButton: {
    pageName: 'SSO | Merge cards dialog"',
    cta: lokaliseTags.SSO_REPLACE_CARDS_BUTTON,
  },
};

const solConfirmationScreent = 'SSO | Attach Card Confirmation screen';
const solConfirmationScreen = {
  pageLoad: {
    pageName: solConfirmationScreent,
  },
  continueButton: {
    pageName: solConfirmationScreent,
    cta: lokaliseTags.SSO_LOYALTY_SETUP_LOYALTY_COMPLETED_BUTTON,
  },
  attachNewButton: {
    pageName: solConfirmationScreent,
    cta: lokaliseTags.SSO_MIGRATION_ATTACH_NEW_CARD,
  },
};

const solLoyaltyCardst = 'SSO | Multiple cards';
const solLoyaltyCards = {
  multiplePageLoad: {
    pageName: solLoyaltyCardst,
  },
  addNewCard: {
    pageName: solLoyaltyCardst,
    cta: lokaliseTags.SSO_PROFILE_ADD_NEW_CARD,
  },
  replaceCard: {
    pageName: solLoyaltyCardst,
    cta: lokaliseTags.SSO_PROFILE_REPLACE_EXISTING_CARD,
  },
};

const solADACMembershipNotPaid = {
  pageLoad: {
    pageName: 'SSO | ADAC Unpaid membership',
  },
  cta: {
    pageName: 'SSO | ADAC Unpaid membership',
    cta: lokaliseTags.SSO_GENERAL_GO_BACK,
  },
};

const loyaltyFuturesWelcomeTags = {
  pageLoad: {
    pageName: solPreAttachScreentext,
  },
  cancelButton: {
    pageName: solPreAttachScreentext,
    cta: lokaliseTags.SSO_GENERAL_NO_CONTINUE,
  },
  continueButton: {
    pageName: solPreAttachScreentext,
    cta: lokaliseTags.SSO_LOYALTY_PHYSICAL_CARD,
  },
};

const pageloadloyaltyfn = function (pageName) {
  const pageLoadObj = {
    pageName: pageName || loyaltyFuturesCardInput.lastPage,
  };
  loyaltyFuturesCardInput.lastPage = pageName;
  return pageLoadObj;
};
const loyaltyFuturesCardInput = {
  lastPage: null,
  pageLoad: pageloadloyaltyfn,
  helpButton: {
    pageName: 'SSO | Attach Loyalty Card Screen',
    cta: lokaliseTags.SSO_LOGIN_CARD_ID_HELP_BUTTON,
  },
  continueButton: (pageName) => ({
    clickarea: pageName,
    cta: lokaliseTags.SSO_LOGIN_CARD_ID_NEXT_BUTTON,
  }),
  register: {
    clickarea: 'SSO | Pre-migration Card Id Screen',
    cta: lokaliseTags.SSO_LOGIN_SIGN_IN_LINK,
  },
  acceptLoyaltyTerms: {
    shellapp_consent_clickarea: solCardInputtext,
    shellapp_consent_name: 'goPlusMarketingOptIn',
  },
};

const loyaltyFuturesCardValidationt =
  'SSO | Pre-migration Card validation screen';
const loyaltyFuturesCardValidation = {
  pageLoad: {
    pageName: loyaltyFuturesCardValidationt,
  },
  nextButton: {
    clickarea: loyaltyFuturesCardValidationt,
    cta: lokaliseTags.SSO_LOGIN_CARD_ID_NEXT_BUTTON,
  },
  phoneLink: {
    clickarea: loyaltyFuturesCardValidationt,
    cta: lokaliseTags.SSO_FORMS_LINK_USE_PHONE_NUMBER,
  },
  dobLink: {
    clickarea: loyaltyFuturesCardValidationt,
    cta: lokaliseTags.SSO_FORMS_LINK_USE_DOB,
  },
};

const loyaltyFuturesCardMigratedDialogt = 'SSO | Card migrated dialog';
const loyaltyFuturesCardMigratedDialog = {
  pageLoad: {
    pageName: loyaltyFuturesCardMigratedDialogt,
  },
  cancelButton: {
    clickarea: loyaltyFuturesCardMigratedDialogt,
    cta: lokaliseTags.SSO_LOGIN_CANCEL_BUTTON,
  },
  loginButton: {
    clickarea: loyaltyFuturesCardMigratedDialogt,
    cta: lokaliseTags.SSO_LOGIN_SIGN_IN_BUTTON,
  },
};

const loyaltyFuturesCardValidationBirthDate = {
  pageLoad: {
    pageName: loyaltyFuturesCardValidationt,
  },
};

const loyaltyFuturesCardValidationMobile = {
  pageLoad: {
    pageName: loyaltyFuturesCardValidationt,
  },
};

const loyaltyFuturesSecurityUpdate = {
  pageLoad: {
    pageName: 'SSO | Pre-migration security update screen',
  },
  continueButton: {
    pageName: 'SSO | Attach Loyalty Card Screen',
    cta: lokaliseTags.SSO_LOGIN_CARD_ID_NEXT_BUTTON,
  },
  nextButton: {
    clickarea: 'SSO | Pre-migration security update screen',
    cta: lokaliseTags.SSO_MIGRATION_GET_STARTED,
  },
};

const loyaltyFuturesMigrationIdPaget = 'SSO | Migration enter your id screen';
const loyaltyFuturesMigrationIdPage = {
  pageLoad: {
    pageName: loyaltyFuturesMigrationIdPaget,
  },
  nextButton: {
    pageName: loyaltyFuturesMigrationIdPaget,
    cta: lokaliseTags.SSO_LOGIN_CARD_ID_NEXT_BUTTON,
  },
  checkBox: {
    shellapp_consent_clickarea: loyaltyFuturesMigrationIdPaget,
    shellapp_consent_name: 'opt in',
  },
  link: {
    clickarea: loyaltyFuturesMigrationIdPaget,
    cta: lokaliseTags.SSO_GENERAL_TC_PRIVACY_POLICY,
  },
  errorMissPhoneNumber: {
    clickarea: loyaltyFuturesMigrationIdPaget,
    erroMessage: lokaliseTags.SSO_FORMS_ERRORS_PHONE_NUMBER,
  },
  errorInvalidNumber: {
    clickarea: loyaltyFuturesMigrationIdPaget,
    erroMessage: lokaliseTags.SSO_FORMS_ERRORS_INVALID_PHONE_NUMBER,
  },
  errorNumberAlreadyExists: {
    clickarea: loyaltyFuturesMigrationIdPaget,
    erroMessage: lokaliseTags.SSO_FORMS_ERRORS_PHONE_EXISTS_LOD,
  },
};

const loyaltyFutureMigrationPasswordPaget =
  'SSO | Migration Enter your password screen';
const loyaltyFutureMigrationPasswordPage = {
  pageLoad: {
    pageName: loyaltyFutureMigrationPasswordPaget,
  },
  nextButton: {
    clickarea: loyaltyFutureMigrationPasswordPaget,
    cta: lokaliseTags.SSO_LOGIN_CARD_ID_NEXT_BUTTON,
  },
  link: {
    clickarea: loyaltyFutureMigrationPasswordPaget,
    cta: lokaliseTags.SSO_GENERAL_GOOGLE_CAPTCHA_TEXT,
  },
  errorMissPassword: {
    clickarea: loyaltyFutureMigrationPasswordPaget,
    errorMessage: lokaliseTags.SSO_FORMS_ERRORS_PASSWORD_IS_REQUIRED,
  },
};

const loyaltyFuturesCommunicationPaget = 'SSO | Migration Consent';
const loyaltyFuturesCommunicationPage = {
  pageLoad: {
    pageName: loyaltyFuturesCommunicationPaget,
  },
  yesButton: {
    clickarea: loyaltyFuturesCommunicationPaget,
    cta: lokaliseTags.SSO_GENERAL_HELPFUL_UPDATES_YES_BUTTON,
  },
  noButton: {
    clickarea: loyaltyFuturesCommunicationPaget,
    cta: lokaliseTags.SSO_GENERAL_HELPFUL_UPDATES_NO_BUTTON,
  },
};

const loyaltyFuturesMigrationConfirmationPaget =
  'SSO | Migration Confirmation screen';
const loyaltyFuturesMigrationConfirmationPage = {
  pageLoad: {
    pageName: loyaltyFuturesMigrationConfirmationPaget,
  },
  continueButton: {
    clickarea: loyaltyFuturesMigrationConfirmationPaget,
    cta: lokaliseTags.SSO_MIGRATION_CONTINUE,
  },
};

const termsAndConditionsPaget = 'SSO | Migration Terms & Conditions screen';
const termsAndConditionsPage = {
  pageLoad: {
    pageName: termsAndConditionsPaget,
  },
  backButton: {
    clickarea: termsAndConditionsPaget,
    cta: lokaliseTags.SSO_BACK,
  },
};

const privacyPolicyPaget = 'SSO Privacy Policy screen';
const privacyPolicyPage = {
  pageLoad: {
    pageName: privacyPolicyPaget,
  },
  backButton: {
    clickarea: privacyPolicyPaget,
    cta: lokaliseTags.SSO_BACK,
  },
};

const loyaltyFuturesMailErrort = 'SSO | Migration Email error screen';
const loyaltyFuturesMailError = {
  pageLoad: {
    pageName: loyaltyFuturesMailErrort,
  },
  continueWithouEmailButton: {
    clickarea: loyaltyFuturesMailErrort,
    cta: lokaliseTags.SSO_CONTINUE_WITHOUT_EMAIL,
  },
};

const loyaltyFuturesPhoneAlreadyExistsDialogt =
  'SSO | Migration Phone number already exist dialog';
const loyaltyFuturesPhoneAlreadyExistsDialog = {
  pageLoad: {
    pageName: loyaltyFuturesPhoneAlreadyExistsDialogt,
  },
  cancelButton: {
    clickarea: loyaltyFuturesPhoneAlreadyExistsDialogt,
    cta: lokaliseTags.SSO_GENERAL_CANCEL,
  },
  signInButton: {
    clickarea: loyaltyFuturesPhoneAlreadyExistsDialogt,
    cta: lokaliseTags.SSO_SIGN_IN,
  },
};

const loyaltyFuturesMergeCardsDialog = {
  pageLoad: {
    pageName: solMergeCardst,
  },
  cancelButton: {
    pageName: solMergeCardst,
    cta: lokaliseTags.SSO_LOGIN_CANCEL_BUTTON,
  },
  mergeButton: {
    pageName: solMergeCardst,
    cta: lokaliseTags.SSO_MERGE_DIALOG_BUTTON,
  },
};

const loyaltyFuturesConfirmationScreen = {
  pageLoad: {
    pageName: solConfirmationScreent,
  },
  cta: {
    pageName: solConfirmationScreent,
    cta: lokaliseTags.SSO_LOYALTY_SETUP_LOYALTY_COMPLETED_BUTTON,
  },
};

const loyaltyFuturesCardLocked = {
  pageLoad: (pageName) => ({
    pageName,
  }),
  ctaPre: {
    clickarea: 'SSO | Pre-migration Card blocked Screen',
    cta: lokaliseTags.SSO_ACCOUNT_LOCKED_CONTINUE_WITHOUT_CARD,
  },
  cta: {
    pageName: 'SSO | Card locked screen',
    cta: lokaliseTags.SSO_ACCOUNT_LOCKED_CONTINUE_WITHOUT_CARD,
  },
};

const loyaltyFuturesCardNotSupportedt = 'SSO | Card not supported screen';
const loyaltyFuturesCardNotSupported = {
  pageLoad: {
    pageName: loyaltyFuturesCardNotSupportedt,
  },
  cta: {
    pageName: loyaltyFuturesCardNotSupportedt,
    cta: lokaliseTags.SSO_GENERAL_GO_BACK,
  },
};

const partialAccountReactivation = {
  pageLoad: {
    pageName: 'SSO | Resend Partialaccount Activation email',
  },
};

const sapHybrisWelcome = {
  pageLoad: {
    pageName: solPreAttachScreentext,
  },
  attachDigital: {
    pageName: solPreAttachScreentext,
    cta: lokaliseTags.SSO_GENERAL_NO_CONTINUE,
  },
  attachPhysical: {
    pageName: solPreAttachScreentext,
    cta: lokaliseTags.SSO_LOYALTY_PHYSICAL_CARD,
  },
};

const sapHybrisCardInput = {
  pageLoad: {
    pageName: solCardInputtext,
  },
  attach: {
    pageName: solCardInputtext,
    cta: lokaliseTags.SSO_LOGIN_CARD_ID_NEXT_BUTTON,
  },
  help: {
    pageName: solCardInputtext,
    cta: 'Help button',
  },
  acceptLoyaltyTerms: {
    shellapp_consent_clickarea: solCardInputtext,
    shellapp_consent_name: 'goPlusMarketingOptIn',
  },
};

const sapHybrisMergeCards = {
  pageLoad: {
    pageName: solMergeCardst,
  },
  cancel: {
    pageName: solMergeCardst,
    clickarea: solMergeCardst,
    cta: lokaliseTags.SSO_GENERAL_CANCEL_BUTTON,
  },
  replace: {
    pageName: solMergeCardst,
    clickarea: solMergeCardst,
    cta: lokaliseTags.SSO_REPLACE_CARDS_BUTTON,
  },
};

const sapHybrisConfirmation = {
  pageLoad: {
    pageName: solConfirmationScreent,
  },
  continue: {
    pageName: solConfirmationScreent,
    cta: lokaliseTags.SSO_LOYALTY_SETUP_LOYALTY_COMPLETED_BUTTON,
  },
};

const otpHelpFlowt = 'SSO | OTP Help screen';
const otpHelpFlow = {
  emailHelpLoad: {
    pageName: otpHelpFlowt,
    securityType: 'email_otp',
  },
  smsHelpLoad: {
    pageName: otpHelpFlowt,
    securityType: 'sms_otp',
  },
  clickResendCode: {
    pageName: otpHelpFlowt,
    cta: 'Resend OTP',
  },
  clickVisitHelp: {
    pageName: otpHelpFlowt,
    cta: 'Visit Help on Shell Support',
  },
  clickSMS: {
    pageName: otpHelpFlowt,
    cta: 'resend_otp_sms',
  },
  clickCall: {
    pageName: otpHelpFlowt,
    cta: 'resend_otp_voice',
  },
  changePhoneNumber: {
    pageName: otpHelpFlowt,
    cta: '2fa change phone number',
  },
};

const setUpNotification = {
  pageLoad: {
    pageName: 'SSO | Card attach loading screen',
  },
};

const otpFlowt = 'SSO | Sign In - enter OTP';
const otpFlow = {
  pageLoad: { pageName: otpFlowt },
  pageUnLoad: { pageName: 'SSO | Sign In - enter OTP - exit page' },
  clickCloseButton: {
    clickarea: otpFlowt,
    cta: 'Exit OTP screen',
  },
  clickNextButton: {
    clickarea: otpFlowt,
    cta: 'Verify OTP',
  },
  clickHavingIssues: {
    clickarea: otpFlowt,
    cta: 'OTP support link',
  },
  codeNotValidError: {
    pageName: otpFlowt,
    errorStatus: 401,
    errorMessage: 'OTP Code is invalid',
  },
  codeExpiredError: {
    pageName: otpFlowt,
    errorStatus: 422,
    errorMessage: 'OTP Code is expired',
  },
};

const twoFaOtpFlow = {
  pageLoad: { pageName: otpFlowt },
  clickNextButton: {
    clickarea: otpFlowt,
    cta: 'Verify OTP',
  },
  clickHavingIssues: {
    clickarea: otpFlowt,
    cta: 'OTP support link',
  },
  codeNotValidError: {
    pageName: otpFlowt,
    errorStatus: 401,
    errorMessage: 'OTP Code is invalid',
  },
  codeExpiredError: {
    pageName: otpFlowt,
    errorStatus: 422,
    errorMessage: 'OTP Code is expired',
  },
};

const twoFaFlow = {
  pagePromptLoad: { pageName: 'SSO | 2fa setup prompt' },
  turnOnTwoFA: {
    clickarea: 'SSO | 2fa setup prompt',
    cta: '2fa_enable',
  },
  enableLater: {
    clickarea: 'SSO | 2fa setup skipped',
    cta: '2fa_enable_later',
  },
  successScreen: {
    pageLoad: { pageName: 'SSO | 2fa setup success' },
    buttonClick: {
      clickarea: 'SSO | 2fa setup success when click on success button',
      cta: '2fa_success_done',
    },
  },
};
const twoFaMobilePaget = 'SSO | 2fa setup phone number';
const twoFaMobilePage = {
  pageLoad: {
    pageName: twoFaMobilePaget,
  },
  clickNext: {
    clickarea: twoFaMobilePaget,
    cta: 'phonenumber_next',
  },
  error: {
    pageName: twoFaMobilePaget,
  },
};

const userBlockedForever = {
  pageLoad: {
    pageName: 'SSO | Sign In Account Locked Forever',
  },
  clickButton: {
    clickarea: 'SSO | Sign In Account Locked Forever help',
    cta: 'sso_blocked_help',
  },
};
const profileSecurityPaget = 'SSO | Profile Security';
const profileSecurityPage = {
  pageLoad: {
    pageName: 'SSO | Profile security',
  },
  clickEnable: {
    clickarea: profileSecurityPaget,
    cta: '2fa_toggle_enable',
  },
  clickDisable: {
    clickarea: profileSecurityPaget,
    cta: '2fa_toggle_disable',
  },
  clickChangePhone: {
    clickarea: profileSecurityPaget,
    cta: '2fa_change_phone_number_text_link',
  },
  clickChangePassword: {
    clickarea: profileSecurityPaget,
    cta: 'change_password',
  },
  password: {
    pageLoad: {
      pageName: 'SSO | 2fa password input when disable ',
    },
    validate: {
      clickarea: 'SSO | 2fa password input when disable ',
      cta: '2fa_validate_password_next_button',
    },
  },
};

const scopesAuthFlowt = 'SSO | Scopes authorization';
const scopesAuthFlowO = 'Oauth Scopes';
const scopesAuthFlow = {
  pageLoad: {
    pageName: scopesAuthFlowt,
    shellapp_cvp: scopesAuthFlowO,
    cvp: scopesAuthFlowO,
  },
  declineScopes: {
    shellapp_cvp: scopesAuthFlowO,
    clickarea: scopesAuthFlowt,
    cta: 'ScopesDecline',
  },
  acceptScopes: {
    shellapp_cvp: scopesAuthFlowO,
    clickarea: scopesAuthFlowt,
    cta: 'ScopesAccept',
  },
  showAlreadyAcceptedScopes: {
    shellapp_cvp: scopesAuthFlowO,
    clickarea: scopesAuthFlowt,
    cta: 'AcceptedScopes',
  },
};

const scopesAcceptedFlow = {
  pageLoad: {
    pageName: 'SSO | Scopes Accepted',
    shellapp_cvp: scopesAuthFlowO,
    cvp: scopesAuthFlowO,
  },
};

const progressiveProfilsing = 'SSO | PP Loyalty onboarding';
const progressiveProfilsings = 'SSO | PP Dynamic form';
const progressiveProfilsingts = 'SSO | PP Consent';
const progressiveProfiling = {
  onboarding: {
    pageLoad: {
      email: {
        pageName: progressiveProfilsing,
        shellapp_cvp: 'SSO',
        triggeredFrom: 'email',
      },
      login: {
        pageName: progressiveProfilsing,
        shellapp_cvp: 'SSO',
        triggeredFrom: 'login',
      },
    },
    clickNext: {
      clickarea: progressiveProfilsing,
      shellapp_cvp: 'SSO',
      cta: 'Lets go',
    },
    errorCodeInvalid: {
      pageName: progressiveProfilsing,
      shellapp_cvp: 'SSO',
      errorMessage: 'PP partner flow - temporary code expired',
    },
  },
  dynamicForm: {
    pageLoad: {
      pageName: progressiveProfilsings,
      shellapp_cvp: 'SSO',
    },
    clickNext: {
      clickarea: progressiveProfilsings,
      shellapp_cvp: 'SSO',
      cta: 'Next',
    },
    clickBack: {
      clickarea: progressiveProfilsings,
      shellapp_cvp: 'SSO',
      cta: 'Back',
    },
    clickSubmit: {
      clickarea: progressiveProfilsings,
      shellapp_cvp: 'SSO',
      cta: 'Finish',
    },
    errorValidation: {
      pageName: progressiveProfilsings,
      shellapp_cvp: 'SSO',
    },
  },
  successScreen: {
    pageLoad: { shellapp_cvp: 'SSO' },
    clickNext: {
      shellapp_cvp: 'SSO',
      cta: 'ok',
    },
  },
  terms: {
    pageLoad: {
      pageName: 'SSO | PP TermsConditions',
      shellapp_cvp: 'SSO',
      triggeredFrom: 'login',
    },
    clickNext: {
      clickarea: 'PP TermsConditions',
      shellapp_cvp: 'SSO',
      cta: 'Accept',
    },
  },
  consents: {
    pageLoad: {
      pageName: progressiveProfilsingts,
      shellapp_cvp: 'SSO',
      triggeredFrom: 'login',
    },
    accept: {
      shellapp_cvp: 'SSO',
      clickarea: 'PP | Consent',
      shellapp_consent_name: 'bundledConsent',
      shellapp_consent_clickarea: progressiveProfilsingts,
      shellapp_consent_value: false,
      cta: 'OptIn',
    },
    decline: {
      shellapp_cvp: 'SSO',
      clickarea: 'PP | Consent',
      shellapp_consent_name: 'bundledConsent',
      shellapp_consent_clickarea: progressiveProfilsingts,
      shellapp_consent_value: true,
      cta: 'OptOut',
    },
  },
  loyaltyRequired: {
    pageLoad: {
      pageName: 'SSO | PP Loyalty Account Required',
      shellapp_cvp: 'SSO',
    },
    clickNext: {
      clickarea: 'SSO | PP Loyalty Account Required',
      shellapp_cvp: 'SSO',
      cta: 'loyalty_website',
    },
    clickAppStore: {
      [ANDROID_APP]: {
        shellapp_cvp: 'SSO',
        cta: `${ANDROID_APP}_Appstore_banner`,
      },
      [IOS_APP]: {
        shellapp_cvp: 'SSO',
        cta: `${IOS_APP}_Appstore_banner`,
      },
    },
  },
};

const updatedTermsLiteral = 'SSO | Updated TermsConditions GO+';
const updatedTermsLiteralb = 'SSO | Updated terms and conditions GO+';
const termsAndConditionsNoLoyaltyMigration = {
  initializeScreen: {
    pageName: updatedTermsLiteral,
  },
  clickAcceptButton: {
    pageName: updatedTermsLiteral,
    clickarea: updatedTermsLiteralb,
    cta: 'updated go+ terms accepted',
  },
  clickTermsAndConditionsFooter: {
    clickarea: updatedTermsLiteralb,
    cta: 'terms_conditions',
  },
  clickCheckBox: {
    pageName: updatedTermsLiteral,
    clickarea: updatedTermsLiteralb,
  },
  enterTcAndPpPage: {
    pageName: 'SSO | Login TermsConditions',
  },
};
const AIRMILES_REGISTER_PAGE_NAME = 'SSO | Registration Airmiles';

const AIRMILES_LOGIN_PAGE_NAME = 'SSO | Sign In Airmiles';
const airmilesgen = 'sso_general.next_label';
const airmileserr = 'sso_general.error_label';
const airmilesgebn = 'SSO | Registration Airmiles Communications';
const airmiles = {
  initializeScreen: {
    pageName: AIRMILES_REGISTER_PAGE_NAME,
  },
  loadFormPage: {
    pageName: `${AIRMILES_REGISTER_PAGE_NAME} - `,
  },
  clickNext: {
    clickarea: `${AIRMILES_REGISTER_PAGE_NAME} - `,
    cta: airmilesgen,
  },
  clickBack: {
    clickarea: `${AIRMILES_REGISTER_PAGE_NAME}`,
    cta: 'sso_general.back_label',
  },
  clickTermsConditions: {
    clickarea: AIRMILES_REGISTER_PAGE_NAME,
    cta: 'terms_conditions',
  },
  clickPrivacyPolicy: {
    clickarea: AIRMILES_REGISTER_PAGE_NAME,
    cta: 'privacy_policy',
  },
  comms: {
    initializeScreen: {
      pageName: airmilesgebn,
    },
    clickNext: {
      clickarea: airmilesgebn,
      cta: airmilesgen,
      shellapp_consent_name: 'bundledConsent',
    },
    clickPrivacyPolicy: {
      clickarea: airmilesgebn,
      cta: 'sso_general.privacy_statement',
    },
  },
  login: {
    initializeScreen: {
      pageName: AIRMILES_LOGIN_PAGE_NAME,
    },
    clickNext: {
      clickarea: AIRMILES_LOGIN_PAGE_NAME,
      cta: airmilesgen,
    },
    clickForgotPassword: {
      clickarea: AIRMILES_LOGIN_PAGE_NAME,
      cta: 'sso_login.forgot_password',
    },
    clickTermsConditions: {
      clickarea: AIRMILES_LOGIN_PAGE_NAME,
      cta: 'terms_conditions',
    },
    clickPrivacyPolicy: {
      clickarea: AIRMILES_LOGIN_PAGE_NAME,
      cta: 'privacy_policy',
    },
  },
  spinner: {
    initializeScreen: {
      pageName: 'SSO | Registration Airmiles creating account',
    },
    clickError: {
      clickarea: 'SSO | Registration Airmiles creating account',
      cta: 'sso_general.error_label',
    },
  },
  conf: {
    initializeScreen: {
      pageName: 'SSO | Registration Airmiles Complete',
    },
    clickNext: {
      clickarea: 'SSO | Registration Airmiles Complete',
      cta: airmilesgen,
    },
    notSynchroClick: {
      clickarea: 'SSO | Airmiles  - Account not synchronised',
      cta: airmilesgen,
    },
    notSynchroInit: {
      clickarea: 'SSO | Airmiles - Account not synchronised',
    },
  },
  linking: {
    initializeScreen: {
      pageName: 'SSO | Sign in Airmiles connecting account',
    },
    clickError: {
      clickarea: 'SSO | Sign in Airmiles connecting account',
      shellapp_cvp: 'SSO',
      cta: airmileserr,
    },
  },

  linkingCardAlreadyUsedError: {
    initializeScreen: {
      pageName: 'SSO | Airmiles - card already has an owner',
      shellapp_cvp: 'SSO',
      clickarea: 'SSO | Airmiles - card already has an owner',
    },
    clickError: {
      clickarea: 'SSO | Airmiles - card already has an owner',
      shellapp_cvp: 'SSO',
      cta: airmileserr,
    },
  },
};

const verificationCodeScreen = {
  pageLoad: {
    pageName: SSO_REGISTRATION_CONFIRM_EMAIL,
  },
};

const accountActivationWalletActions = {
  pageLoad: {
    pageName: SSO_REGISTRATION_CONFIRM_EMAIL,
  },
};

// DE CONSENTS
const deConsentsAnalytics = {
  loginFlow: {
    aboutComms: {
      pageLoad: {
        pageName: 'SSO | Exclusive offers Sign In',
      },
      clickNext: {
        pageName: 'SSO | Exclusive offers Sign In',
        clickarea: 'SSO | Exclusive offers Sign In',
        cta: 'sso_general_next_label',
      },
    },
    marketingComms: {
      pageLoad: {
        pageName: 'SSO | Communications pop-up Sign In',
      },
      clickYes: {
        pageName: 'SSO | Communications pop-up Sign In',
        clickarea: 'SSO | Communications pop-up Sign In',
        shellapp_consent_name: 'bundledConsent',
        shellapp_consent_value: true,
      },
      clickNo: {
        pageName: 'SSO | Communications pop-up Sign In',
        clickarea: 'SSO | Communications pop-up Sign In',
        shellapp_consent_name: 'bundledConsent',
        shellapp_consent_value: false,
      },
      clickPP: {
        pageName: 'SSO | Communications pop-up Sign In',
        clickarea: 'SSO | Communications pop-up Sign In',
        cta: 'sso_general_tc_pp',
      },
    },
    skippingComms: {
      aboutComms: {
        pageName: 'SSO | Exclusive offers Sign In',
        clickarea: 'SSO | Exclusive offers Sign In',
        shellapp_consent_name: 'bundledConsent',
        shellapp_consent_value: 'Skip',
      },
      marketingComms: {
        pageName: 'SSO | Communications pop-up Sign In',
        clickarea: 'SSO | Communications pop-up Sign In',
        shellapp_consent_name: 'bundledConsent',
        shellapp_consent_value: 'Skip',
      },
    },
  },
  registerFlow: {
    aboutComms: {
      pageLoad: {
        pageName: 'SSO | Exclusive offers Registration',
      },
      clickNext: {
        pageName: 'SSO | Exclusive offers Registration',
        clickarea: 'SSO | Exclusive offers Registration',
        cta: 'sso_general_next_label',
      },
    },
    marketingComms: {
      pageLoad: {
        pageName: 'SSO | Communications pop-up Registration',
      },
      clickYes: {
        pageName: 'SSO | Communications pop-up Registration',
        clickarea: 'SSO | Communications pop-up Registration',
        shellapp_consent_name: 'bundledConsent',
        shellapp_consent_value: true,
      },
      clickNo: {
        pageName: 'SSO | Communications pop-up Registration',
        clickarea: 'SSO | Communications pop-up Registration',
        shellapp_consent_name: 'bundledConsent',
        shellapp_consent_value: false,
      },
      clickPP: {
        pageName: 'SSO | Communications pop-up Registration',
        clickarea: 'SSO | Communications pop-up Registration',
        cta: 'sso_general_tc_pp',
      },
    },
    skippingComms: {
      aboutComms: {
        pageName: 'SSO | Exclusive offers Registration',
        clickarea: 'SSO | Exclusive offers Registration',
        cta: 'sso_general_next_back',
      },
      marketingComms: {
        pageName: 'SSO | Communications pop-up Registration',
        clickarea: 'SSO | Communications pop-up Registration',
        cta: 'sso_general_next_back',
      },
    },
  },
};

// VEHICLE PREFERENCE
const vehiclePreference = {
  pageLoad: {
    pageName: 'SSO | Registration Vehicle type',
  },
  clickNext: {
    pageName: 'SSO | Registration Vehicle type',
    clickarea: 'SSO | Registration Vehicle type',
    cta: 'sso_general.next_label',
  },
};

const adobeAnalyticTags = {
  [LOGIN_PAGE]: loginAdobeTags,
  [REGISTER_PAGE]: registerAdobeTags,
  [EDIT_PROFILE_PAGE]: editProfileAdobeTag,
  [FORGOT_PASSWORD_PAGE]: forgotPassword,
  [RESET_PASSWORD_PAGE]: resetPassword,
  [CHANGE_EMAIL_PAGE]: changeEmailAdobeTags,
  [CHANGE_PASSWORD_PAGE]: changePasswordTags,
  [VERIFY_USER_PAGE]: verifyUserPageTags,
  [SOL_MIGRATION_PAGE]: solMigrationCard,
  [LOYALTY_INITIAL_PAGE]: solCardInput,
  [LOYALTY_FUTURES_CARD_INPUT_PAGE]: loyaltyFuturesCardInput,
  [LOYALTY_FUTURES_MIGRATION_ID_PAGE]: loyaltyFuturesMigrationIdPage,
  [MIGRATION_PASSWORD_PAGE]: loyaltyFutureMigrationPasswordPage,
  [SOL_PAYONSITE_DETAILS_PAGE]: solPayOnSiteForm,
  homeTags,
  resetPasswordSecurityUpdate,
  loyaltyFuturesCardValidation,
  loyaltyFuturesSecurityUpdate,
  loyaltyFuturesCardMigratedDialog,
  loyaltyFuturesCardValidationBirthDate,
  loyaltyFuturesCardValidationMobile,
  consentsAdobeTags,
  accountLockedTags,
  solMigrationOverview,
  solFuelCardDialog,
  solMigrationEmail,
  solMigrationPassword,
  solMigrationConfirmation,
  solPayOnSiteError,
  solSmartDealDialog,
  solPreAttachScreen,
  solCardDialogInfo,
  solCardNotSupported,
  solCardMigrated,
  solConfirmationScreen,
  solMergeCards,
  solLoyaltyCards,
  solADACMembershipNotPaid,
  loyaltyFuturesWelcomeTags,
  loyaltyFuturesMergeCardsDialog,
  loyaltyFuturesConfirmationScreen,
  loyaltyFuturesCardLocked,
  loyaltyFuturesCardNotSupported,
  partialAccountReactivation,
  sapHybrisWelcome,
  sapHybrisCardInput,
  sapHybrisConfirmation,
  sapHybrisMergeCards,
  loyaltyFuturesMigrationConfirmationPage,
  termsAndConditionsPage,
  privacyPolicyPage,
  loyaltyFuturesMailError,
  loyaltyFuturesPhoneAlreadyExistsDialog,
  loyaltyFuturesCommunicationPage,
  setUpNotification,
  otpHelpFlow,
  otpFlow,
  scopesAcceptedFlow,
  scopesAuthFlow,
  twoFaFlow,
  twoFaMobilePage,
  twoFaOtpFlow,
  profileSecurityPage,
  userBlockedForever,
  progressiveProfiling,
  termsAndConditionsNoLoyaltyMigration,
  verificationCodeScreen,
  airmiles,
  accountActivationWalletActions,
  deConsentsAnalytics,
  vehiclePreference,
  ssoLoginTags,
  ssoRegisterTags
};

export default adobeAnalyticTags;
